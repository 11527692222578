import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Commanservice from "../../../CommanService/commanService";
import "./signIn.scss";
import { useDispatch, useSelector } from "react-redux";
import { isProcessToCheckoutClick, getLoginDetailsData, isGuestIdLogin, isLoginModalOpen, isRegisterModalOpen, storeSectionUrl, storeForgotVar }
  from "../../../Redux/action"
import Loader from "../../CommanUIComp/Loader/loader";
import ToastComponent from "../../CommanUIComp/Toast/toast";
import Modal from "react-bootstrap/Modal";
import Register from "../SignUp/signUp";
import { validateEmail, RandomId, isEmpty, storeType } from "../../CommanFunctions/commanFunctions"

const SignIn = (props) => {

  let [loading, setLoading] = useState(false);
  const [toastShow, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const [otherLogo, setOtherLogo] = useState({})
  const [validationForms, setValidation] = useState({
    email: false,
    password: false,
  });
  const [isHiddenPassword, setHiddenPassword] = useState(true)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const navigate = useNavigate();

  const logologin = selector.storeGetLogo

  useEffect(() => {
    if (logologin.length > 0) {
      logologin.map((L, i) => {
        if (L.logo_type === "OTHER") {
          setOtherLogo(L)
        }
        return L
      })
    }
    document.querySelector("body").scrollTo(0, 0);
    window.scrollTo(0, 0);
    dispatch(storeForgotVar(false));
    document.getElementById("form-id").reset();
  }, [selector.storeEntityId, logologin, dispatch]);

  const Forgot = () => {
    setEmail("");
    dispatch(storeForgotVar(true));
  };

  const Back = () => {
    dispatch(storeForgotVar(false));
  };

  const registerOnClick = () => {
    dispatch(isRegisterModalOpen(true))
    dispatch(isLoginModalOpen(false))
  };

  const ForgotMail = () => {
    validateField("email", email);
    const areTrue = validationForms.email;
    if (areTrue) {
      if (Object.keys(selector.storeEntityId).length > 0) {
        setLoading(true);

        const obj = {
          a: "ForgetPassword",
          store_id: selector.storeEntityId.mini_program_id,
          email: email,
          mail_url: window.location.origin,
          store_type: storeType
        };

        ForgotMailApi(obj)
      }
    } else {
      let fieldValidationErrors = formErrors;

      if (!validationForms.email) {
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(fieldValidationErrors.email);
      }
      setLoading(false);
    }
  };

  const ForgotMailApi = (obj) => {
    Commanservice.postLaravelApi("AuthController", obj)
      .then((res) => {
        if (res.data.success === 1) {
          setToastOpen(true);
          setIsSuccess(true);
          setToastMsg(res.data.message);
          dispatch(storeForgotVar(true));
          loading = false;
          setLoading(false);
          if (!loading) {
            setTimeout(() => {
              dispatch(isLoginModalOpen(false))
              dispatch(isRegisterModalOpen(false))
              navigate("/");
            }, 1000);
          }
        } else {
          setToastOpen(true);
          setIsSuccess(false);
          setToastMsg(res.data.message);
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false);
        loading = false;
        setLoading(false);
        if (!loading) {
          setTimeout(() => {
            dispatch(isLoginModalOpen(false))
            dispatch(isRegisterModalOpen(false))
            navigate("/");
          }, 1000);
        }
      });
  }

  const Login = () => {
    validateField("email", email);
    validateField("password", password);

    const areTrue = Object.values(validationForms).every(
      (value) => value === true
    );

    if (areTrue) {
      if (Object.keys(selector.storeEntityId).length > 0) {
        setLoading(true);
        var obj = {
          a: "login",
          email: email,
          password: password,
          store_id: selector.storeEntityId.mini_program_id
        };
        loginApi(obj)
      }
    } else {
      let fieldValidationErrors = formErrors;
      if (!validationForms.email) {
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(fieldValidationErrors.email);
      } else if (!validationForms.password) {
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(fieldValidationErrors.password);
      }
      setLoading(false);
    }
  };

  const loginApi = (obj) => {
    Commanservice.postLaravelApi("AuthController", obj).then((res) => {
      if (res["data"]["success"] === 1) {
        setToastOpen(true);
        setIsSuccess(true);
        setToastMsg(res.data.message);

        if (Object.keys(res.data.data).length > 0) {
          const loginData = res.data.data;
          dispatch(getLoginDetailsData(res["data"]["data"]));
          guestToMember(loginData)
          guestToFavourite(loginData)
        }
      } else {
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(res.data.message);
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false)
    })
  }

  const guestToMember = (loginData) => {
    const guestObj = {
      a: "GuestToMember",
      store_id: selector.storeEntityId.mini_program_id,
      guest_id: RandomId,
      member_id: loginData.member_id,
      customer_name: "guest",
    };
    Commanservice.postLaravelApi("CartMaster", guestObj).then((guestObj) => {
      dispatch(isGuestIdLogin(true))
    }).catch(() => {
      setLoading(false)
    })
  }

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;

    switch (fieldName) {
      case "email":
        if (validateEmail(value)) {
          fieldValidationErrors.email = "";
          validationForms.email = true;
        } else if (value.length === 0) {
          fieldValidationErrors.email = "Email Required";
          validationForms.email = false;
        } else {
          fieldValidationErrors.email = "Email is invalid";
          validationForms.email = false;
        }
        setValidation(validationForms);
        break;

      case "password":
        if (value.length === 0) {
          validationForms.password = false;
          fieldValidationErrors.password = "Password Required";
        } else {
          validationForms.password = value.length >= 8;
          fieldValidationErrors.password = validationForms.password
            ? ""
            : "Maximum 8 letter in Password";
        }
        break;

      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setValidation(validationForms);
  };

  const loginPage = () => {
    setEmail("");
    setPassword("");
    dispatch(storeForgotVar(false));
  };

  const guestToFavourite = (loginData) => {
    const obj = {
      "a": "GuestToFavoriteMember",
      "store_id": selector.storeEntityId.mini_program_id,
      "guest_id": RandomId,
      "member_id": loginData.member_id
    }

    Commanservice.postLaravelApi("FavouriteController", obj).then((res) => {
      setEmail("");
      setPassword("");
      loading = false;
      setLoading(false);
      if (!loading) {
        dispatch(isLoginModalOpen(false))
        dispatch(isRegisterModalOpen(false))
        if (!selector.isProcessToCheckoutClick) {
          dispatch(storeSectionUrl("shopping-cart"))
          navigate("/shopping-cart")
        } else {
          navigate("/");
        }
      }
    }).catch(() => {
      setEmail("");
      setPassword("");
      loading = false;
      setLoading(false);
      if (!loading) {
        dispatch(isLoginModalOpen(false))
        dispatch(isRegisterModalOpen(false))
        if (!selector.isProcessToCheckoutClick) {
          dispatch(storeSectionUrl("shopping-cart"))
          navigate("/shopping-cart")
        }
        else {
          navigate("/");
        }
      }
    })
  }

  return (
    <>
      <section>
        <Modal className={`modal-xl ${isEmpty(otherLogo.image) == "" ? 'without_images' : ''}`} show={selector.isLoginModalOpen} aria-labelledby="contained-modal-title-vcenter" id="login" centered keyboard={true} tabIndex="-1" aria-hidden="true">

          <div className="h-100">
            {loading && <Loader />}
            {<div className="colse-icon" onClick={() => { dispatch(isLoginModalOpen(false)); dispatch(isRegisterModalOpen(false)); if (selector.isCartLoginClick) { dispatch(isProcessToCheckoutClick(true)); }; if (selector.isChangePasswordLoginClick) { navigate("/"); } }} aria-label="Close">
              <i className="ic_remove"></i>
            </div>}

            <div className="login_info">
              <div className={`login_info_inner ${Object.keys(otherLogo).length === 0 && ""}`}>
                {Object.keys(otherLogo).length > 0 && otherLogo.image !== "" && <div className="login_info_left bg-image" style={{ background: 'url("' + otherLogo.image + '") no-repeat center center/cover' }}>
                  {/* <div className="banner_img">
                    <React.Fragment >
                      <img src={otherLogo.image} className="h-100 w-100" alt=" preview" />
                    </React.Fragment>
                  </div> */}
                </div>}
                <div className="login_info_right">
                  <div className="login-field">
                    <div className="login-info">
                      <div className="text-center">
                        {logologin.length > 0 && (
                          <>
                            {logologin.map((L, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {isEmpty(L.image) !== "" && (
                                    <>
                                      {L.logo_type === "HEADER" ? (
                                        <div className="App-Logo mx-auto">
                                          <img alt="" src={L.image} className="mb-3" />
                                        </div>

                                      ) : ("")}
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </>
                        )}
                        {selector.isforgot === false ? (
                          <h5 className="main-title01 bodycontent-sub-title-size my-3">LOGIN</h5>
                        ) : (
                          <h5 className="main-title01 bodycontent-sub-title-size my-3">FORGOT PASSWORD?</h5>
                        )}
                      </div>
                      <div className="w-100">
                        <form id="form-id" autoComplete="off">
                          <div className="mb-3">
                            <label className="main-sub-title d-block mb-1">Email*</label>
                            <div className="d-flex align-items-center">
                              <i className="ic_email input-icon"></i>
                              <input type="text" className="input-text" id="Email" value={email} autoComplete="off" onChange={(e) => { setEmail(e.target.value); }} placeholder="Enter Email" />
                            </div>
                            <span className="input-warning mt-1" ></span>
                          </div>

                          {selector.isforgot === false ? (
                            <div className="mb-2">
                              <label className="main-sub-title d-block mb-1">Password*</label>
                              <div className="d-flex align-items-center position-relative">
                                <i className="ic_password input-icon"></i>
                                <input type={isHiddenPassword ? "password" : "text"} className="input-text" id="Password" autoComplete="new-password" value={password} onChange={(e) => { setPassword(e.target.value); }} placeholder="Enter Password" />
                                <i onClick={() => { setHiddenPassword(!isHiddenPassword) }} className={` ${isHiddenPassword ? "ic_eye_close" : "ic_eye_open"}  input-eye-icon `}></i>
                              </div>
                              <span className="input-warning mt-1"></span>
                            </div>
                          ) : ("")}

                          <div className="text-end">
                            {selector.isforgot === false ? (
                              <a href="/" onClick={(event) => { event.preventDefault(); Forgot(); }} className="d-block main-sub-title bodycontent-text-size   content-hover">Forgot Your Password?</a>
                            ) : (
                              <a href="/" onClick={(event) => { event.preventDefault(); Back(); }} className="d-block main-sub-title bodycontent-text-size content-hover"> Back To Login?</a>
                            )}
                          </div>

                          <div className="my-3 text-center">
                            {selector.isforgot === false ? (
                              <>
                                <button type="button" disabled={loading} onClick={() => Login()} className="btn button-thamebalck" >
                                  SIGN IN
                                </button>
                              </>
                            ) : (
                              <button type="button" onClick={() => ForgotMail()} className="btn button-thamebalck">
                                CONTINUE
                              </button>
                            )}
                          </div>

                          {selector.isforgot === false ? (
                            <div className="">
                              <div className="text-center main-sub-title bodycontent-text-size mt-3">
                                Don’t have an account?
                                <span onClick={() => registerOnClick()} className="mx-1  bodycontent-text-size content-text-color content-hover fw-500 text-decoration-underline" aria-label="Close">
                                  Sign Up
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="">
                              <div className="text-center main-sub-title bodycontent-text-size mt-3">
                                Remember your password?
                                <span className="mx-1 content-text-color bodycontent-text-size content-hover fw-500 text-decoration-underline" onClick={loginPage} >
                                  Login
                                </span>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ToastComponent
              show={toastShow}
              success={isSuccess}
              onClose={() => setToastOpen(false)}
              header={isSuccess ? "Success" : "Error"}
              toastBody={<div>{toastMsg}</div>}
            />
          </div>
        </Modal>

        {selector.isRegisterModalOpen === true ? (
          <Register
            isNavigateToHome={props.isNavigateToHome}
            logoRegister={selector.storeGetLogo}
            isCloseButtonHide={props.isCloseButtonHide}
            isCartRegister={false}
          />
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default SignIn;
