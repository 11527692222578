import React, { lazy } from "react";
import { lazyWithRetry } from "../../Components/CommanFunctions/commanFunctions";
import InquiryRoute from "../InquiryRoute/inquiryRoute";

const ProtectedRoute = lazyWithRetry(() => import("../ProtectedRoute/protectedRoute"))
const AuthRoute = lazyWithRetry(() => import("../AuthRoute/authRoute"))
const DashRouterPage = lazyWithRetry(() => import("../../Components/CommanUIComp/DashRouterPage/dashRouterPage"))
const Blog = lazyWithRetry(() => import("../../Components/Policy/Blog/blog"))
const SingleProductSetting = lazyWithRetry(() => import("../../Components/Product/ProductA/StartWithSetting/ProductDetailsSetting/singleProductSetting"))
const BlogCategary = lazyWithRetry(() => import("../../Components/Policy/Blog/BlogCategary/blogCategary"))
const Home = lazyWithRetry(() => import("../../Components/HomePage/Home/home"));
const StartWithDiamond = lazyWithRetry(() =>
  import("../../Components/Product/ProductA/StartWithDiamond/StartWithDiamond/startWithDiamond")
);
const SingleProductDiamond = lazyWithRetry(() => import("../../Components/Product/ProductA/StartWithDiamond/ProductDetailsDiamond/singleProductDiamond"))
const AboutUs = lazyWithRetry(() => import("../../Components/Policy/AboutUs/aboutUs"));
const Cart = lazyWithRetry(() => import("../../Components/Cart/Cart/cart"));
const Faq = lazyWithRetry(() => import("../../Components/Policy/FAQ/faq"));
const SuccessOrder = lazyWithRetry(() =>
  import("../../Components/Cart/SuccessOrder/successOrder")
);
const Register = lazyWithRetry(() => import("../../Components/Profile/SignUp/signUp"));
const Profile = lazyWithRetry(() => import("../../Components/Profile/Profile/profile"));
const LooseDiamond = lazyWithRetry(() =>
  import("../../Components/Product/ProductB/StartWithLoose/startWithLoose")
);
const ContactUs = lazyWithRetry(() => import("../../Components/Policy/ContactUs/contactUs"));
const StartWithSetting = lazyWithRetry(() => import("../../Components/Product/ProductA/StartWithSetting/StartWithSetting/startWithSetting"))
const ResetPassword = lazyWithRetry(() =>
  import("../../Components/Profile/ResetPassword/resetPassword"));

const PolicyList = lazyWithRetry(() =>
  import("../../Components/Policy/PolicyList/policyList")
);
const Favourite = lazyWithRetry(() =>
  import("../../Components/Favourite/favourite")
);
const OrderDeatils = lazyWithRetry(() => import("../../Components/Profile/OrderDetails/orderDetails"));
const ErrorPage = lazyWithRetry(() => import("../../Components/CommanUIComp/PageNotFound/pageNotFound"));
const Journey = lazyWithRetry(() => import("../../Components/Profile/JourneyCatalogue/Journeycatalog"));
const Viewjourney = lazyWithRetry(() => import("../../Components/Profile/JourneyCatalogueView/Viewjourney"));
const StartWithDiamondSelection = lazyWithRetry(() => import("../../Components/Diy/CreateYourDiamondRing/StartWithDiamondSelection"));
const SingleDiamondPage = lazyWithRetry(() => import("../../Components/Diy/SingleDiamondPage/SingleDiamondPage"));
const RingJewellery = lazyWithRetry(() => import('../../Components/Diy/RingJewellery/RingJewellery'));
const SingleJewelleryRing = lazyWithRetry(() => import('../../Components/Diy/SingleJewelleryRing/SingleJewelleryRing'));
const DiyProduct = lazyWithRetry(() => import('../../Components/Diy/DiyProduct/DiyProduct'));

export const RouteComponent = [
  {
    path: "*",
    component: ErrorPage,
    element: <ErrorPage />
  },
  {
    path: "/-",
    component: DashRouterPage,
    element: <DashRouterPage />
  },
  {
    path: "/",
    component: Home,
    element: <Home />
  },
  {
    path: "/products/:verticalCode",
    component: StartWithSetting,
    element: <StartWithSetting />
  },
  {
    path: "/products/:verticalCode/:variantId",
    component: SingleProductSetting,
    element: <SingleProductSetting />
  },
  {
    path: "/products/:verticalCode/:productKey/:value",
    component: StartWithSetting,
    element: <StartWithSetting />
  },
  {
    path: "/products/:verticalCode/:productKey/:value/:variantId",
    component: SingleProductSetting,
    element: <SingleProductSetting />
  },
  {
    path: "/jewellery",
    component: StartWithSetting,
    element: <StartWithSetting />
  },
  // {
  //   path: "/jewellery/:productKey/:value",
  //   component: StartWithSetting,
  //   element: <StartWithSetting />
  // },
  // {
  //   path: "/products/:verticalCode/:productKey/:value/:variantId",
  //   component: SingleProductSetting,
  //   element: <SingleProductSetting />
  // },
  {
    path: "/jewellery/:productKey/:value/:variantId",
    component: SingleProductSetting,
    element: <SingleProductSetting />
  },
  {
    path: "/jewellery/start-with-a-setting",
    component: StartWithSetting,
    element: <StartWithSetting />
  },
  {
    path: "/jewellery/start-with-a-setting/:variantId",
    component: SingleProductSetting,
    element: <SingleProductSetting />
  },
  {
    path: "/jewellery/start-with-a-diamond",
    component: StartWithSetting,
    element: <StartWithSetting />
  },
  // {
  //   path: "/frame",
  //   component: StartWithSetting,
  //   element: <StartWithSetting />
  // },
  // {
  //   path: "/frame/:productKey/:value/",
  //   component: StartWithSetting,
  //   element: <StartWithSetting />
  // },
  // {
  //   path: "/frame/:productKey/:value/:variantId",
  //   component: SingleProductSetting,
  //   element: <SingleProductSetting />
  // },
  {
    path: "/certificate-diamond",
    component: StartWithDiamond,
    element: <StartWithDiamond />
  },
  {
    path: "/certificate-diamond/:shape/:item",
    component: StartWithDiamond,
    element: <StartWithDiamond />
  },
  {
    path: "/certificate-diamond/:shape/:item/:certificateNo",
    component: SingleProductDiamond,
    element: <SingleProductDiamond />
  },
  {
    path: "/certificate-diamond/start-with-a-diamond",
    component: StartWithSetting,
    element: <StartWithSetting />
  },
  {
    path: "/certificate-diamond/start-with-a-setting",
    component: StartWithSetting,
    element: <StartWithSetting />
  },
  {
    path: "/lab-grown-diamond/:shape/:item",
    component: StartWithDiamond,
    element: <StartWithDiamond />
  },
  {
    path: "/lab-grown-diamond/:shape/:item/:certificateNo",
    component: SingleProductDiamond,
    element: <SingleProductDiamond />
  },
  {
    path: "/lab-grown-diamond",
    component: StartWithDiamond,
    element: <StartWithDiamond />
  },
  {
    path: "/lab-grown-gemstone/:shape/:item",
    component: StartWithDiamond,
    element: <StartWithDiamond />
  },
  {
    path: "/lab-grown-gemstone/:shape/:item/:certificateNo",
    component: SingleProductDiamond,
    element: <SingleProductDiamond />
  },
  {
    path: "/lab-grown-gemstone",
    component: StartWithDiamond,
    element: <StartWithDiamond />
  },
  {
    path: "/loose-diamond",
    component: LooseDiamond,
    element: <LooseDiamond />
  },
  {
    path: "/loose-diamond/:shapeName/:shape",
    component: LooseDiamond,
    element: <LooseDiamond />
  },
  {
    path: "/gemstone-diamond",
    component: LooseDiamond,
    element: <LooseDiamond />
  },
  {
    path: "/gemstone-diamond/:shapeName/:shape",
    component: LooseDiamond,
    element: <LooseDiamond />
  },
  {
    path: "/favourite-items",
    component: Favourite,
    element: <Favourite />
  },
  {
    path: "/aboutus",
    component: AboutUs,
    element: <AboutUs />
  },
  {
    path: "/start-with-diamond",
    component: StartWithDiamondSelection,
    element: <StartWithDiamondSelection />
  },
  {
    path: "/start-with-diamond/:shape",
    component: SingleDiamondPage,
    element: <SingleDiamondPage />
  },
  {
    path: "/start-with-diamond/:value/:shapeCode",
    component: RingJewellery,
    element: <RingJewellery />
  },
  {
    path: "/start-with-diamond/jewellery/:ringId",
    component: SingleJewelleryRing,
    element: <SingleJewelleryRing />
  },
  {
    path: "/start-with-diamond/jewellery/ring/diy",
    component: DiyProduct,
    element: <DiyProduct />
  },
  {
    path: "/shopping-cart",
    component: Cart,
    element:
      <InquiryRoute>
        <Cart />
      </InquiryRoute>
  },
  {
    path: "/faq",
    component: Faq,
    element: <Faq />
  },
  {
    path: "/profile",
    component: Profile,
    element:
      <ProtectedRoute>
        <InquiryRoute>
          <Profile />
        </InquiryRoute>
      </ProtectedRoute>

  },
  {
    path: "/profile/:item/:value",
    component: Profile,
    element: <ProtectedRoute>
      <InquiryRoute>
        <Profile />
      </InquiryRoute>
    </ProtectedRoute>
  },

  {
    path: "/contactus",
    component: ContactUs,
    element: <ContactUs />
  },
  {
    path: "/reset-password/:key",
    component: ResetPassword,
    element:
      <AuthRoute>
        <ResetPassword />
      </AuthRoute>

  },
  {
    path: "/success-order/:succesOrderId",
    component: SuccessOrder,
    element: <ProtectedRoute> <InquiryRoute>  <SuccessOrder /> </InquiryRoute>
    </ProtectedRoute>
  },
  {
    path: "/success-order/:succesOrderId/:orderAmnt",
    component: SuccessOrder,
    element: <ProtectedRoute> <InquiryRoute>  <SuccessOrder /> </InquiryRoute>
    </ProtectedRoute>
  },
  {
    path: "/cancel-order/:succesOrderId",
    component: SuccessOrder,
    element: <ProtectedRoute> <InquiryRoute>  <SuccessOrder /> </InquiryRoute></ProtectedRoute>
  },
  {
    path: "/order-details/:orderId",
    component: OrderDeatils,
    element: <ProtectedRoute> <InquiryRoute>  <OrderDeatils /> </InquiryRoute></ProtectedRoute>
  },
  {
    path: "/blog",
    component: Blog,
    element: <Blog />
  },
  {
    path: "/blog/:uniqueId/:categaryId",
    component: BlogCategary,
    element: <BlogCategary />
  },
  {
    path: "/:policyName",
    component: PolicyList,
    element: <PolicyList />
  },
  {
    path: "/profile/journey-catalog/:true",
    component: Profile,
    element: <Profile />
  },
  {
    path: "/profile/journey-catalog/",
    component: Profile,
    element: <Profile />
  },
  {
    path: "/viewjourney/:unique_id",
    component: Viewjourney,
    element: <Viewjourney />
  },
  {
    path: "/viewjourney/:unique_id/:type",
    component: Viewjourney,
    element: <Viewjourney />
  },

];

