import { Toast } from "react-bootstrap";
import React from "react";
import "./toast.scss"

const ToastComponent = (props) => {
  return (
    <Toast
      autohide={true}
      onClose={() => props.onClose(false)}
      show={props.show}
      style={{
        top: "5%",
        backgroundColor: props.success ? "#198754" : "#dc3545",
        color: "white",
        right: "1%",
        zIndex: "10000000000000000000000000001"
      }}
      animation={false}
      className={props.className + " position-fixed rounded-0"}
    >
      <Toast.Body>
        <div className="d-flex">
          <div style={{ width: "90%" }}>
            {props.toastBody}
          </div>
          <button type="button" className="btn-close ml-auto" style={{ fontSize: "12px" }} aria-label="Close" onClick={() => props.onClose(false)} data-dismiss="toast"><i className="ic_remove"></i></button>
        </div>
      </Toast.Body>
    </Toast>
  );
};
export default ToastComponent;
