import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Commanservice from "../../../CommanService/commanService";
import "./footer.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../CommanUIComp/Loader/loader";
import ToastComponent from "../../CommanUIComp/Toast/toast"
import { getCountOfCartItems, storeFavCount, storeSectionUrl, storeSubNavbarName } from "../../../Redux/action";
import { RandomId, splicaArrayIntoColumns, validateEmail, isEmpty, storeType } from "../../CommanFunctions/commanFunctions";

const Footer = (props) => {

  const [loader, setLoader] = useState(false);
  const [toastShow, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [onceUpdated, setOnceUpdated] = useState(true)

  const [storeEntityId, setStoreEntityId] = useState({});
  const [footerDataStore, setFooterDataStore] = useState({});
  const [footerDataLogo, setFooterDataLogo] = useState([]);
  const [footerDataContent, setFooterDataContent] = useState([
    { code: "blog", data: [] },
    { code: "aboutus", data: [] },
    { code: "ContactUs", data: [] }
  ]);
  const [footerSocialLinkData, setfooterSocialLinkData] = useState([]);
  const [footerContactData, setFooterContactData] = useState([]);
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({ email: "", });
  const [validationForms, setValidation] = useState({ email: false, });

  const selector = useSelector((state) => state);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const getCountApi = useCallback((data) => {
    const getCount = {
      "a": "get_count",
      "store_id": isEmpty(data.mini_program_id),
      "user_id": Object.keys(isEmpty(selector.data.member_id)).length > 0 ? isEmpty(selector.data.member_id) : RandomId
    }

    Commanservice.postLaravelApi("CartMaster", getCount).then((res) => {
      if (res.data.success === 1) {
        const data = res.data.data
        dispatch(getCountOfCartItems(isEmpty(data.cart_count) !== "" ? data.cart_count : 0));
        dispatch(storeFavCount(isEmpty(data.favourite_count) !== "" ? data.favourite_count : 0))
        setLoader(false)
      } else {
        dispatch(getCountOfCartItems(0));
        dispatch(storeFavCount(0))
        setToastOpen(true)
        setIsSuccess(false)
        setToastMsg(res.data.message)
        setLoader(false)
      }
    }).catch(() => {
      dispatch(getCountOfCartItems(0));
      dispatch(storeFavCount(0))
      setLoader(false)
    })
  }, [dispatch, selector.data])

  const getFooterData = useCallback((data) => {
    const obj = {
      "a": "getHomeFooterDetail",
      "store_id": data.mini_program_id,
      user_id: Object.keys(selector.data).length > 0 ? selector.data.member_id : RandomId,
      type: storeType
    }

    Commanservice.postLaravelApi("SectionDetail", obj).then((res) => {
      if (res["data"]["success"] === 1) {
        const footerData = res["data"]["data"];
        if (Object.keys(footerData).length > 0) {
          const footerLogo = res["data"]["data"]["logo_data"];
          const footerContentData = res["data"]["data"]["content_data"];
          const footerSocialData = res["data"]["data"]["socialmedia_link"];
          const footerContactData = res["data"]["data"]["contact_data"];

          setFooterDataStore(res.data.data);
          setFooterDataLogo(footerLogo);
          setfooterSocialLinkData(footerSocialData);

          dispatch(getCountOfCartItems(isEmpty(footerData.cart_count) !== "" ? footerData.cart_count : 0));
          dispatch(storeFavCount(isEmpty(footerData.favourite_count) !== "" ? footerData.favourite_count : 0))
          if (footerContactData.length != 0) {
            footerContactData[0]['address'] = footerContactData[0]['building'];
            if (isEmpty(footerContactData[0]['building_name']) != '') {
              footerContactData[0]['address'] = footerContactData[0]['address'] + ', ' + footerContactData[0]['building_name']
            }
            footerContactData[0]['address'] = footerContactData[0]['address'] + ', ' + footerContactData[0]['street'];
            if (isEmpty(footerContactData[0]['description']) != '') {
              footerContactData[0]['address'] = footerContactData[0]['address'] + ', ' + footerContactData[0]['description']
            }
            if (isEmpty(footerContactData[0]['city_name']) != '') {
              footerContactData[0]['address'] = footerContactData[0]['address'] + ', ' + footerContactData[0]['city_name']
            }
            if (isEmpty(footerContactData[0]['pincode']) != '') {
              footerContactData[0]['address'] = footerContactData[0]['address'] + '-' + footerContactData[0]['pincode']
            }
            footerContactData[0]['address'] = footerContactData[0]['address'] + ', ' + footerContactData[0]['state_name'] + ', ' + footerContactData[0]['country_name'] + '.'
          }
          setFooterContactData(footerContactData);
          const contentArr = [];
          footerContentData.forEach((e) => {
            const contentObj = {
              code: e.category_name,
              data: e.data ? e.data : []
            };
            contentArr.push(contentObj);
          });
          setFooterDataContent(contentArr);
        } else {
          getCountApi(data)
        }
      } else {
        setToastOpen(true)
        setIsSuccess(false)
        setToastMsg(res.data.message)
        getCountApi(data)
      }
    }).catch(() => {
      getCountApi(data)
    })
  }, [selector.data, dispatch, getCountApi])

  useEffect(() => {
    setOnceUpdated(false)
  }, [selector.data])

  useEffect(() => {
    if (Object.keys(selector.storeEntityId).length > 0 && props.isStoreApiCalling === true) {
      if (!onceUpdated) {
        setOnceUpdated(true)
        const data = selector.storeEntityId;
        getFooterData(data);
        setStoreEntityId(data);
      }
    }
  }, [selector.storeEntityId, getFooterData, selector.data, onceUpdated, props.isStoreApiCalling]);

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    switch (fieldName) {
      case "email":
        if (validateEmail(value)) {
          fieldValidationErrors.email = "";
          validationForms.email = true;
        } else if (value.length === 0) {
          fieldValidationErrors.email = "Email Required";
          validationForms.email = false;
        } else {
          fieldValidationErrors.email = "Email is invalid";
          validationForms.email = false;
        }
        setValidation(validationForms);
        break;

      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setValidation(validationForms);
  };

  const subscribeEmail = () => {
    validateField("email", email);
    const areTrue = Object.values(validationForms).every(
      (value) => value === true
    );
    if (areTrue) {
      setLoader(true);
      const obj = {
        a: "AddUpdateSubscribers",
        unique_id: "",
        store_id: storeEntityId.mini_program_id,
        email: email,
      };

      subScribeApiCalling(obj)
    } else {
      let fieldValidationErrors = formErrors;
      if (!validationForms.email) {
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(fieldValidationErrors.email);
      }
      setLoader(false)
    }
  };

  const subScribeApiCalling = (obj) => {
    Commanservice.postLaravelApi("Subscribers", obj).then((res) => {
      if (res.data.success === 1) {
        setEmail("")
        setToastOpen(true);
        setIsSuccess(true);
        setToastMsg(res.data.message);
        setLoader(false);
      } else {
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(res.data.message);
        setLoader(false);
      }
    }).catch(() => {
      setLoader(false);
    })
  }

  return (
    <footer id="footer">
      {loader && <Loader />}

      {(Object.keys(footerDataStore).length > 0 ?
        <div className="container">
          <div className="section_margin">
            <div className="row align-items-start justify-content-between">
              <div className="col-12 col-sm-6 col-xl-3">
                {footerDataLogo.length > 0 && footerDataLogo.map((L, i) => (
                  <React.Fragment key={i}>
                    {L.logo_type === "FOOTER" && (
                      <React.Fragment>
                        {isEmpty(L.image) !== "" &&
                          <Link to={'/'}>
                            <img alt="" src={L.image} className="img-fluid" />
                          </Link>
                        }
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ))}
                <h6 className="footer-title">Get Latest Updates</h6>
                <form>
                  <div className="footer-subcribe">
                    <input type="text" value={email} onChange={(event) => { setEmail(event.target.value); }} className="inputText" placeholder="ENTER YOUR E-MAIL" />
                    <button type="button" onClick={() => { subscribeEmail(); }} className="btn buttonubscribe">SUBSCRIBE</button>
                  </div>
                </form>
              </div>

              <div className="col-12 col-sm-6 col-xl-4">
                <h6 className="footer-title">OUR POLICY</h6>
                {footerDataContent.length > 10 ? <div className="gridView">
                  {footerDataContent.length > 0 &&
                    (splicaArrayIntoColumns(footerDataContent, 2, true) || []).map((val, index) => (<ul key={index}>
                      {val.length > 0 && val.map((e, i) => (
                        <li key={i} className="my-1 footer-text">
                          <Link onClick={(event) => { if (window.location.pathname === `/${e.code.toLowerCase().split(" ").join("-")}`) { event.stopPropagation(); event.preventDefault(); navigate("/-"); } sessionStorage.setItem("storeUrl", `/${e.code.toLowerCase().split(" ").join("-")}`); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl(e.code.toLowerCase().split(" ").join("-"))); }} className={`${e.code.toLowerCase().split(" ").join("-") === selector.storeSectionUrl ? "Footer-Active-Color" : ""} text-capitalize`} to={`/${e.code.toLowerCase().split(" ").join("-")}`} >
                            {isEmpty(e.code) !== "" ? e.code : ""}
                          </Link>
                        </li>
                      ))}
                    </ul>))}
                </div> :
                  <ul>
                    {footerDataContent.length > 0 && (footerDataContent || []).map((e, i) => {
                      return (
                        <li key={i} className="my-1 footer-text-">
                          <Link onClick={(event) => {
                            if (window.location.pathname === `/${e.code.toLowerCase().split(" ").join("-")}`) {
                              event.stopPropagation();
                              event.preventDefault();
                              navigate("/-");
                            } sessionStorage.setItem("storeUrl", `/${e.code.toLowerCase().split(" ").join("-")}`); dispatch(storeSubNavbarName("")); dispatch(storeSectionUrl(e.code.toLowerCase().split(" ").join("-")));
                          }} className={`${e.code.toLowerCase().split(" ").join("-") === selector.storeSectionUrl ? "Footer-Active-Color" : ""} text-capitalize`} to={`/${e.code.toLowerCase().split(" ").join("-")}`}>
                            {isEmpty(e.code) !== "" ? e.code : ""}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                }
              </div>

              <div className="col-12 col-sm-6 col-xl-3">
                {footerContactData.length > 0 && <React.Fragment>
                  <h6 className="footer-title">GET IN TOUCH</h6>
                  {footerContactData.length > 0 && footerContactData.map((e, i) => (
                    <ul key={i}>

                      <li className="d-flex align-items-start my-1 footer-text">
                        <i className="ic_home" />
                        <div className="ms-2 address">{e?.address}</div>
                      </li>

                      {isEmpty(e?.email) != '' ?
                        <li className="d-flex align-items-center my-1 footer-text">
                          <a href={`mailto:${e?.email}`}> <i className="ic_envelope my-1" />
                            <span className="ms-2"> {e?.email}</span></a>
                        </li>
                        : ''}
                      {isEmpty(e?.mobile) != '' ?
                        <li className="d-flex align-items-center my-1 footer-text">
                          <a href={`tel:${e?.mobile}`}>
                            <i className="ic_telephone my-1" /><span className="ms-2">{e?.country_code} {e?.mobile} </span></a>
                        </li>
                        : ''}
                    </ul>
                  ))}
                </React.Fragment>}
              </div>

              <div className="col-12 col-sm-6 col-xl-2">
                {footerSocialLinkData.length > 0 && <React.Fragment>
                  <h6 className="footer-title">FOLLOW US</h6>
                  <div className="social-icon">
                    {footerSocialLinkData.length > 0 && footerSocialLinkData.map((s, i) => (
                      <a key={i} href={s.url} rel="noopener noreferrer" target="_blank" className="d-flex align-items-center">
                        <div className="icon_img">
                          <img alt="" src={s.image} className="img_verical_align" />
                          {s.name !== "" ?
                            <div className="icon_text">
                              <p className="mb-0 footer-text-color footer-text-size footer-text-hover">{s.name}</p>
                            </div>
                            : ""}
                        </div>
                      </a>
                    ))}
                  </div>
                </React.Fragment>}
              </div>
            </div>
          </div>
        </div>
        : <React.Fragment></React.Fragment>)}
      <div className="footer-bottom">
        <div className="container">
          <p>© {storeEntityId.store_name} {new Date().getFullYear()}. Powered by{" "}
            <a href='https://upqor.com' rel="noreferrer" target="_blank">UPQOR PRIVATE LIMITED</a>.
          </p>
        </div>
      </div>

      <ToastComponent show={toastShow} success={isSuccess} onClose={() => setToastOpen(false)} header={isSuccess ? "Success" : "Error"} toastBody={<div>{toastMsg}</div>} />
    </footer>
  );
};

export default Footer;
