import "./../src/Assets/css/bootstrap.min.css";
import "./App.scss";
import "./icon.scss";
import React, { useState, useEffect, Suspense } from "react";
import { domain } from "./CommanService/commanService";
import Commanservice from "./CommanService/commanService";
import {
  isGuestIdLogin, isLoginModalOpen, storeFavCount, getCountOfCartItems, isProcessToCheckoutClick, isRegisterModalOpen, openGetCartNavbar, storeEntityId,
  isChangePasswordLoginClick, isCartLoginClick, storeCurrency, storeColorData, Taburl, getJourneyCatalogueId
} from "./Redux/action";
import { Routes, Route, useNavigate } from "react-router-dom";
import ToastComponent from "../src/Components/CommanUIComp/Toast/toast"
import { useDispatch, useSelector } from "react-redux";
import Footer from "../src/Components/HeaderFooter/Footer/footer"
import Header from "../src/Components/HeaderFooter/Header/header"
import { RouteComponent } from "../src/Routes/Routes/routes";
import Loader from "../src/Components/CommanUIComp/Loader/loader";
import { useCallback } from "react";
import PageUnderConstruction from "./Components/CommanUIComp/PageUnderConstruction/pageUnderConstruction";
import { diamoSessionData, isEmpty, jewelSessionData, looseSessionData } from "./Components/CommanFunctions/commanFunctions";
// import OneSignal from 'react-onesignal';

const App = () => {

  const [loader, setLoder] = useState(true);
  const [toastShow, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [onceUpdated, setOnceUpdated] = useState(false)

  const [domaindata, setdomaindata] = useState({});
  const [isPageSiteOpen, setisPageSiteOpen] = useState(false)
  let [isStoreApiCalling, setIsStoreApiCalling] = useState(JSON.parse(sessionStorage.getItem("isStoreApiCalling")))

  const selector = useSelector(state => state)
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const path = window.location.pathname

  const getStoreData = useCallback(() => {
    const getEntityId = {
      a: "GetStoreData",
      store_domain: domain,
      SITDeveloper: "1"
    };

    setLoder(true)

    Commanservice.postApi("EmbeddedPageMaster", getEntityId).then((res) => {
      if (res.data.success === 1) {
        const data = res.data.data;
        setdomaindata(data);
        sessionStorage.setItem("storeData", JSON.stringify(data))
        if (Object.keys(data).length > 0) {
          dispatch(storeEntityId(res.data.data));
          document.title = isEmpty(res.data.data.store_name)
          if (isEmpty(data.transaction_b2c_id) === "") {
            setIsStoreApiCalling(false)
            setisPageSiteOpen(true)
            setLoder(false)
          } else {
            setIsStoreApiCalling(true)
            setisPageSiteOpen(false)
            colorChangesAPI(data);
          }

          if (isEmpty(data.is_primary) === "0") {
            dispatch(storeCurrency(isEmpty(data.store_currency)))
          } else {
            if (selector.storeCurrency === "") {
              dispatch(storeCurrency(isEmpty(data.store_currency)))
            }
          }

        } else {
          setisPageSiteOpen(true)
          dispatch(storeEntityId({}));
          setIsStoreApiCalling(false)
          setLoder(false);
        }

      } else {
        setisPageSiteOpen(true)
        sessionStorage.setItem("storeData", false)
        setIsStoreApiCalling(false)
        dispatch(getCountOfCartItems(0))
        dispatch(storeFavCount(0))
        dispatch(storeEntityId({}));
        setToastOpen(true);
        setIsSuccess(false);
        setToastMsg(res.data.message);
        setLoder(false);
      }
    }).catch(() => {
      setisPageSiteOpen(true)
      sessionStorage.setItem("storeData", false)
      setIsStoreApiCalling(true)
      dispatch(getCountOfCartItems(0))
      dispatch(storeFavCount(0))
      dispatch(storeEntityId({}));
      setLoder(false);
    })
  }, [dispatch, selector.storeCurrency])

  // const runOneSignal = () => {

  //   OneSignal.init({
  //     appId: '3bfa3b37-b6f1-403f-a7f9-c48b3e2db489', allowLocalhostAsSecureOrigin: true,
  //     notifyButton: {
  //       enable: true, /* Required to use the Subscription Bell */
  //       size: 'medium', /* One of 'small', 'medium', or 'large' */
  //       theme: 'default', /* One of 'default' (red-white) or 'inverse" (white-red) */
  //       position: 'bottom-right', /* Either 'bottom-left' or 'bottom-right' */
  //       offset: {
  //         bottom: '0px',
  //         left: '0px', /* Only applied if bottom-left */
  //         right: '0px' /* Only applied if bottom-right */
  //       },
  //       showCredit: false, /* Hide the OneSignal logo */
  //       text: {
  //         'tip.state.unsubscribed': 'Subscribe to notifications',
  //         'tip.state.subscribed': "You're subscribed to notifications",
  //         'tip.state.blocked': "You've blocked notifications",
  //         'message.prenotify': 'Click to subscribe to notifications',
  //         'message.action.subscribed': "Thanks for subscribing!",
  //         'message.action.resubscribed': "You're subscribed to notifications",
  //         'message.action.unsubscribed': "You won't receive notifications again",
  //         'dialog.main.title': 'Manage Site Notifications',
  //         'dialog.main.button.subscribe': 'SUBSCRIBE',
  //         'dialog.main.button.unsubscribe': 'UNSUBSCRIBE',
  //         'dialog.blocked.title': 'Unblock Notifications',
  //         'dialog.blocked.message': "Follow these instructions to allow notifications:"
  //       }
  //     }

  //   }).then((res) => {
  //     OneSignal.showSlidedownPrompt();

  //     OneSignal.on('subscriptionChange', (isSubscribed) => {
  //       if (isSubscribed) {
  //         OneSignal.getUserId((player_id) => {

  //         })
  //       }
  //     });

  //   })
  // }
  useEffect(() => {
    if (path.split('/')[1] == "profile") {
      navigate('/profile')
    }
  }, [])

  useEffect(() => {
    const getStoreDataItem = JSON.parse(sessionStorage.getItem("storeData"))

    if (!onceUpdated) {
      jewelSessionData()
      diamoSessionData()
      looseSessionData()

      setOnceUpdated(true)
      dispatch(storeEntityId({}))
      dispatch(openGetCartNavbar(false))
      dispatch(isLoginModalOpen(false))
      dispatch(isRegisterModalOpen(false))
      dispatch(isProcessToCheckoutClick(true))
      dispatch(isCartLoginClick(false))
      dispatch(isChangePasswordLoginClick(false))

      if (Object.keys(selector.data).length > 0) {
        dispatch(isGuestIdLogin(true))
      } else {
        dispatch(isGuestIdLogin(false))
      }

      if (getStoreDataItem === null || getStoreDataItem === false) {
        getStoreData()
      }
      if (getStoreDataItem !== null && getStoreDataItem !== false) {
        setdomaindata(getStoreDataItem)
        if (Object.keys(getStoreDataItem).length > 0) {
          dispatch(storeEntityId(getStoreDataItem))
          document.title = getStoreDataItem.store_name
          colorChangesAPI(getStoreDataItem);
          setIsStoreApiCalling(true)
        } else {
          dispatch(storeEntityId({}))
          setIsStoreApiCalling(false)
        }
      }
    }
  }, [getStoreData, onceUpdated, dispatch, selector.data]);

  const colorChangesAPI = (data) => {
    //eslint-disable-next-line
    var isTrue = false
    var preview = '1';
    if ((window.location.href).includes('preview') === true) {
      preview = '1';
    } else {
      preview = '0';
    }
    const obj = {
      a: "PublishTemplate",
      transaction_id: data.transaction_b2c_id,
      host: domain,
      SITDeveloper: "1",
      preview: preview,
      secret_key: data.secret_key
    }

    Commanservice.postApi("TemplateMaster", obj).then((res) => {
      if (res["data"]["success"] === 1) {
        isTrue = true
        setisPageSiteOpen(false)
        const ColorData = res["data"]["data"];
        const publish_json = isEmpty(ColorData["publish_json"]) !== "" ? (ColorData["publish_json"]) : "";
        const publish = isEmpty(JSON.parse(publish_json)) !== "" ? JSON.parse(publish_json) : {};

        if (Object.keys(publish).length > 0) {
          dispatch(storeColorData(publish))
          if (isEmpty(publish["header"]) !== "") {
            if (isEmpty(publish["header"]["Top_header"]) !== "") {
              isEmpty(publish["header"]["Top_header"].Bg_color) !== "" && document.documentElement.style.setProperty('--theme-top-header-bg-color', publish["header"]["Top_header"].Bg_color);

              isEmpty(publish["header"]["Top_header"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-top-header-text-color', publish["header"]["Top_header"].Text_color);

              isEmpty(publish["header"]["Top_header"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-top-header-hover-color', publish["header"]["Top_header"].Text_hover);
            }

            if (isEmpty(publish["header"]["second_header"]) !== "") {
              isEmpty(publish["header"]["second_header"].Title_color) !== "" && document.documentElement.style.setProperty('--theme-Second-header-title-color', publish["header"]["second_header"].Title_color);

              isEmpty(publish["header"]["second_header"].Sub_title_color) !== "" && document.documentElement.style.setProperty('--theme-Second-header-sub-title-color', publish["header"]["second_header"].Sub_title_color);

              isEmpty(publish["header"]["second_header"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-Second-header-text-color', publish["header"]["second_header"].Text_color);

              isEmpty(publish["header"]["second_header"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-Second-header-hover-color', publish["header"]["second_header"].Text_hover);

              isEmpty(publish["header"]["second_header"].Bg_color) !== "" && document.documentElement.style.setProperty('--theme-Second-header-bg-color', publish["header"]["second_header"].Bg_color);
            }

            if (isEmpty(publish["header"]["slider"]) !== "") {
              isEmpty(publish["header"]["slider"].Title_color) !== "" && document.documentElement.style.setProperty('--theme-slider-title-color', publish["header"]["slider"].Title_color);

              isEmpty(publish["header"]["slider"].Sub_title_color) !== "" && document.documentElement.style.setProperty('--theme-slider-sub-title-color', publish["header"]["slider"].Sub_title_color);

              isEmpty(publish["header"]["slider"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-slider-text-color', publish["header"]["slider"].Text_color);

              isEmpty(publish["header"]["slider"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-slider-hover-color', publish["header"]["slider"].Text_hover);
            }
          }

          if (isEmpty(publish["footer"]) !== "" && isEmpty(publish["footer"]["footer_data"]) !== "") {

            isEmpty(publish["footer"]["footer_data"].Title_color) !== "" && document.documentElement.style.setProperty('--theme-footer-title-color', publish["footer"]["footer_data"].Title_color);

            isEmpty(publish["footer"]["footer_data"].Sub_title_color) !== "" && document.documentElement.style.setProperty('--footer-sub-title-color', publish["footer"]["footer_data"].Sub_title_color);

            isEmpty(publish["footer"]["footer_data"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-footer-text-color', publish["footer"]["footer_data"].Text_color);

            isEmpty(publish["footer"]["footer_data"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-footer-hover-color', publish["footer"]["footer_data"].Text_hover);

            isEmpty(publish["footer"]["footer_data"].Bg_color) !== "" && document.documentElement.style.setProperty('--theme-footer-bg-color', publish["footer"]["footer_data"].Bg_color);
          }

          if (isEmpty(publish["bodyContent"]) !== "" && isEmpty(publish["bodyContent"]["body_content"]) !== "") {

            isEmpty(publish["bodyContent"]["body_content"].Title_color) !== "" && document.documentElement.style.setProperty('--theme-body-title-color', publish["bodyContent"]["body_content"].Title_color);

            isEmpty(publish["bodyContent"]["body_content"].Sub_title_color) !== "" && document.documentElement.style.setProperty('--theme-body-sub-title-color', publish["bodyContent"]["body_content"].Sub_title_color);

            isEmpty(publish["bodyContent"]["body_content"].Text_color) !== "" && document.documentElement.style.setProperty('--theme-body-text-color', publish["bodyContent"]["body_content"].Text_color);

            isEmpty(publish["bodyContent"]["body_content"].Text_hover) !== "" && document.documentElement.style.setProperty('--theme-body-hover-color', publish["bodyContent"]["body_content"].Text_hover);

            isEmpty(publish["bodyContent"]["body_content"].Bg_color) !== "" && document.documentElement.style.setProperty('--theme-body-bg-color', publish["bodyContent"]["body_content"].Bg_color);

            isEmpty(publish["bodyContent"]["body_content"].sec_Bg_color) !== "" && document.documentElement.style.setProperty('--theme-body-sub-bg-color', publish["bodyContent"]["body_content"].sec_Bg_color);

            isEmpty(publish["bodyContent"]["body_content"].nav_tab_color) !== "" && document.documentElement.style.setProperty('--theme-body-nav-tab-color', publish["bodyContent"]["body_content"].nav_tab_color);
          }

          if (isEmpty(publish["button"]) !== "" && isEmpty(publish["button"]["button_data"]) !== "") {

            isEmpty(publish["button"]["button_data"].button_bg_color) !== "" && document.documentElement.style.setProperty('--theme-btn-primary-bg-color', publish["button"]["button_data"].button_bg_color);


            isEmpty(publish["button"]["button_data"].button_text_color) !== "" && document.documentElement.style.setProperty('--theme-btn-primary-text-color', publish["button"]["button_data"].button_text_color);

            isEmpty(publish["button"]["button_data"].button_text_hover) !== "" && document.documentElement.style.setProperty('--theme-btn-primary-hover-color', publish["button"]["button_data"].button_text_hover);
          }

          if (isEmpty(publish["button"]) !== "" && isEmpty(publish["button"]["button_data"]) !== "") {

            isEmpty(publish["button"]["button_data"].sec_button_bg_color) !== "" && document.documentElement.style.setProperty('--theme-btn-secondary-bg-color', publish["button"]["button_data"].sec_button_bg_color);


            isEmpty(publish["button"]["button_data"].sec_button_text_color) !== "" && document.documentElement.style.setProperty('--theme-btn-secondary-text-color', publish["button"]["button_data"].sec_button_text_color);

            isEmpty(publish["button"]["button_data"].sec_button_text_hover) !== "" && document.documentElement.style.setProperty('--theme-btn-secondary-hover-color', publish["button"]["button_data"].sec_button_text_hover);
          }


          setLoder(false)
        } else {
          dispatch(storeColorData({}))
          setLoder(false)
        }
      } else {
        dispatch(storeColorData({}))
        setisPageSiteOpen(true)
        setToastOpen(true)
        setIsSuccess(false)
        setToastMsg(res.data.message)
        setLoder(false)
      }
    }).catch(() => {
      if (isTrue === false) {
        dispatch(storeColorData({}))
        setisPageSiteOpen(true)
        setLoder(false)
      }
    })
  }

  useEffect(() => {
    var count = 0;
    var count2 = 0;
    document.querySelector("body").scrollTo(0, 0);
    window.scrollTo(0, 0);
    setInterval(() => {
      // Product Height
      var className2 = document.querySelector("figure");
      if (className2 !== null && className2 !== undefined && className2 !== "") {
        var divElement2 = document.querySelector("figure");
        if (divElement2.getBoundingClientRect() !== null && divElement2.getBoundingClientRect() !== undefined) {
          var elemRect2 = divElement2.getBoundingClientRect();
          var elemHeight2 = elemRect2.width;
          if (elemHeight2 !== 0) {
            var height2 = document.getElementsByTagName('figure');
            if (height2.length > count2) {
              height2[count2].setAttribute("style", `height:${elemHeight2 + "px"};`);
              count2++;
            } else {
              count2 = 0;
            }
          }
        }
      }

      // Skeleton Height
      var className = document.getElementsByClassName("figure");
      if (className !== null && className !== undefined && className.length > 0) {
        var divElement = document.querySelector("figure");
        if (divElement.getBoundingClientRect() !== null && divElement.getBoundingClientRect() !== undefined) {
          var elemRect = divElement.getBoundingClientRect();
          var elemHeight = elemRect.width;
          var height = document.getElementsByClassName('figure');
          if (height.length > count) {
            height[count].setAttribute("style", `height:${elemHeight + "px"};`);
            count++;
          } else {
            count = 0;
          }
        }
      }

      var loader = document.getElementById("loader");
      var body = document.getElementById("body");
      let loaderArr = document.querySelectorAll("#loader")
      let NavbarToggle = document.getElementsByClassName('navbar-toggler collapsed')
      let Nvbar = document.getElementsByClassName('main_header navbar-collapse collapse')
      let activeTabInNav = document.getElementsByClassName("nav-link dropdown-navbar active")
      let myclass = document.getElementsByClassName('Mega-menu my-class')


      if (loaderArr.length > 0) {
        if (loaderArr.length > 1) {
          for (let i = 1; i < loaderArr.length; i++) {
            loaderArr[i].style.display = "none"
          }
        } else {
          loaderArr[0].style.display = "block"
        }
      }

      if (loader !== null) {
        if (window.location.pathname === "/") {
          document.querySelector("body").scrollTo(0, 0);
          window.scrollTo(0, 0);
          // $(window).scrollTop(0)
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0
          body.setAttribute("style", "overflow:hidden;")
        } else {
          // window.scrollTo(0, 0);
          // // $(window).scrollTop(0)
          // document.body.scrollTop = 0;
          // document.documentElement.scrollTop = 0
          // body.setAttribute("style", "overflow:hidden;")
        }
      } else {
        body.setAttribute("style", "overflow:visible;")
      }

      if (loader) {
        body.setAttribute("style", "overflow:visible;")
        if (NavbarToggle.length > 0) {
          NavbarToggle[0].classList.remove('collapsed')
        }
        if (activeTabInNav.length > 0 && myclass.length > 0) {
          activeTabInNav[0].classList.remove('active')
          myclass[0].classList.remove('my-class')
        }
      } else {
        if (Nvbar[0] !== null && Nvbar[0] !== undefined) {
          if (loader !== null || NavbarToggle.length > 0) {
            Nvbar[0].classList.add('show')
            // body.setAttribute("style", "overflow:hidden;")
            body.classList.add('overflow-hidden')
            body.classList.remove('overflow-visible')
          } else {
            if (NavbarToggle.length == 0) {
              Nvbar[0].classList.remove('show')
              // body.setAttribute("style", "overflow:visible;")
              body.classList.add('overflow-visible')
              body.classList.remove('overflow-hidden')
            }
          }
        } else { }
      }
    }, 1);
  }, [])
  // var active = document.getElementsByClassName("owl-item cloned active center");
  // console.log(active.length,"active");
  return (
    <>
      {loader && <Loader />}

      {!isPageSiteOpen ?
        <>
          <Header
            loader={loader}
            setLoder={setLoder}
            isStoreApiCalling={isStoreApiCalling}
            domaindata={domaindata}
            selector={domaindata}
            navigate={navigate}
          />

          <div className="stoon">
            <Suspense fallback={<Loader />}>
              <Routes>
                {RouteComponent.map((route, index) => {
                  return <Route
                    key={index}
                    path={route.path}
                    component={route.component}
                    element={
                      <Suspense fallback={<Loader />}>
                        {route.element}
                      </Suspense>
                    }
                  />
                }
                )}
              </Routes>
            </Suspense>
          </div>

          <Footer
            isStoreApiCalling={isStoreApiCalling}
            domaindata={domaindata}
          />
        </>
        :
        <PageUnderConstruction />
      }

      <ToastComponent
        show={toastShow}
        success={isSuccess}
        onClose={() => setToastOpen(false)}
        header={isSuccess ? "Success" : "Error"}
        toastBody={<div>{toastMsg}</div>}
      />
    </>
  );
};

export default App;
