import "./signUp.scss";
import React, { useState, useEffect } from "react";
import Commanservice from "../../../CommanService/commanService";
import ToastComponent from "../../CommanUIComp/Toast/toast";
import { isProcessToCheckoutClick, getLoginDetailsData, isGuestIdLogin, isLoginModalOpen, isRegisterModalOpen, storeForgotVar, storeSectionUrl }
  from "../../../Redux/action"
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Login from "../SignIn/signIn";
import Loader from "../../CommanUIComp/Loader/loader";
import { useNavigate } from "react-router-dom";
import { RandomId, isEmpty, onlyNumbers, validateEmail, validateWithOnlyLetters } from "../../CommanFunctions/commanFunctions";
import { Form } from "react-bootstrap";
import Select from "react-select";
import commanService from "../../../CommanService/commanService";

const SignUp = (props) => {

  let [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastShow, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  const [isHiddenPassword, setIsHiddenPassword] = useState(true)
  const [otherLogo, setOtherLogo] = useState({})
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    country: '',
    phoneumber: '',
  });
  const [validationForms, setValidation] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    country: false,
    phoneumber: false,
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("")
  const [countryShortCode, setCountryShortcode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [gender, setGender] = useState("")
  const [phoneumber, setPhoneNumber] = useState("")
  const [city, setCity] = useState('')
  const [state, setState] = useState('')


  const [getCountry, setGetCountry] = useState([])
  const [phoneCodeDataDrp, setPhoneCodeDataDrp] = useState([]);

  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoRegister = selector.storeGetLogo
  var minDate = new Date();
  var dd = minDate.getDate();
  var mm = minDate.getMonth() + 1;
  var yyyy = minDate.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  minDate = yyyy + "-" + mm + "-" + dd;


  useEffect(() => {
    if (logoRegister.length > 0) {
      logoRegister.map((L, i) => {
        if (L.logo_type === "OTHER") {
          setOtherLogo(L)
        }
        return L
      })
    }
    document.getElementById("registerForm").reset();
  }, [selector.storeEntityId, logoRegister]);
  useEffect(() => {
    GetCountryData()
  }, [])

  const GetCountryData = () => {
    const GetCountry = {
      a: "getCountry",
      SITDeveloper: "1"
    }
    commanService.postApi('/TechnicalManagement', GetCountry).then((res) => {
      if (res.data.success == 1) {
        sessionStorage.setItem('country_data', JSON.stringify(res.data.data));
        countrySetDrp();
        // setGetCountry(res.data.data)
      }
    })
  }

  const countrySetDrp = () => {
    var data = JSON.parse(sessionStorage.getItem('country_data'));
    for (let c = 0; c < data.length; c++) {
      data[c]['value'] = data[c]['name'];
      data[c]['label'] = data[c]['name'];
    }
    data.splice(0, 0, { value: '', label: 'Select Your Country' })
    setGetCountry(data)
    setTimeout(() => {
      phoneCodeSetDrp();
    });
  }

  const phoneCodeSetDrp = () => {
    var pdata = JSON.parse(sessionStorage.getItem('country_data'));
    for (let c = 0; c < pdata.length; c++) {
      pdata[c]['value'] = pdata[c]['phonecode'];
      pdata[c]['label'] = pdata[c]['phonecode'] + ' - ' + pdata[c]['name'];
    }
    pdata.splice(0, 0, { value: '', label: 'Phone Code' })
    setPhoneCodeDataDrp(pdata)
  }

  const ChangesCountry = (e) => {
    if (e.value !== "") {
      for (let i = 0; i < getCountry.length; i++) {
        if (getCountry[i].name === e.value) {
          setCountryShortcode(getCountry[i].sortname)
          setCountryId(getCountry[i].id);
          setCountry(getCountry[i])
        }
      }
    } else {
      setCountryShortcode('')
      setCountryId('');
      setCountry('')
    }
  }

  const ChangesPhoneCode = (e) => {
    if (e.value !== "") {
      setPhoneCode(e)
    } else {
      setPhoneCode('')
    }
  }

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    switch (fieldName) {
      case "firstName":
        if (value.length <= 0) {
          validationForms.firstName = false;
          fieldValidationErrors.firstName = "First Name Required";
        } else {
          validationForms.firstName = true;
          fieldValidationErrors.firstName = ""
        }
        break;

      case "lastName":
        if (value.length <= 0) {
          validationForms.lastName = false;
          fieldValidationErrors.lastName = "Last Name Required";
        } else {
          validationForms.lastName = true;
          fieldValidationErrors.lastName = ""
        }
        break;

      case "email":
        if (validateEmail(value)) {
          fieldValidationErrors.email = "";
          validationForms.email = true;
        } else if (value.length === 0) {
          fieldValidationErrors.email = "Email Required";
          validationForms.email = false;
        } else {
          fieldValidationErrors.email = "Email is invalid";
          validationForms.email = false;
        }
        setValidation(validationForms);
        break;

      case "password":
        if (value.length === 0) {
          fieldValidationErrors.password = "Password Required";
          validationForms.password = false;
        } else {
          validationForms.password = value.length >= 8;
          fieldValidationErrors.password = validationForms.password
            ? ""
            : "Maximum 8 letter in Password";
        }
        break;

      case "Country":
        if (isEmpty(country.value) == '') {
          validationForms.country = false;
          fieldValidationErrors.country = "Country Required";
        } else {
          validationForms.country = true;
          fieldValidationErrors.country = ""
        }
        break;

      case "city":
        if (value.length <= 0) {
          validationForms.city = false;
          fieldValidationErrors.city = "City Required";
        } else {
          validationForms.city = true;
          fieldValidationErrors.city = ""
        }
        break;

      case "phoneCode":
        if (isEmpty(phoneCode.value) == '') {
          validationForms.phoneCode = false;
          fieldValidationErrors.phoneCode = "Phone Code Required";
        } else {
          validationForms.phoneCode = true;
          fieldValidationErrors.phoneCode = ""
        }
        break;

      case "mobile":
        // if (getCountry.length === 0) {
        //   setToastOpen(true)
        //   setIsSuccess(false)
        //   setToastMsg("Select Country")
        // }
        if (value.length <= 0) {
          validationForms.phoneumber = false;
          fieldValidationErrors.phoneumber = "Mobile Number Required";
        } else if (value.length < 8) {
          validationForms.phoneumber = false;
          fieldValidationErrors.phoneumber = "Min 8 numbers in Mobile Number"
        } else if (value.length > 14) {
          validationForms.phoneumber = false;
          fieldValidationErrors.phoneumber = "Max 14 numbers in Mobile Number"
        } else if (value.length >= 8 && value.length <= 14) {
          validationForms.phoneumber = true;
          fieldValidationErrors.phoneumber = "";
        }
        break;

      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setValidation(validationForms);
  };

  const Registers = () => {
    validateField("firstName", firstName);
    validateField("lastName", lastName);
    validateField("email", email);
    validateField("password", password);
    validateField("Country", country.value);
    validateField("city", city);
    validateField("phoneCode", phoneCode.value);
    validateField("mobile", phoneumber);

    const areTrue = Object.values(validationForms).every(
      (value) => value === true
    );
    if (areTrue) {
      if (Object.keys(selector.storeEntityId).length > 0) {
        setLoading(true);
        const data = selector.storeEntityId;
        var obj = {
          a: "signup",
          store_id: data.mini_program_id,
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
          mobile_no: phoneumber,
          country: country.value,
          country_id: countryId,
          country_code: phoneCode.value,
          country_short_code: countryShortCode,
          state: state,
          city: city,
          gender: gender,
        };
        RegisterApiCall(obj)
      }
    } else {
      let fieldValidationErrors = formErrors;
      setIsSuccess(false);
      if (!validationForms.firstName) {
        setToastOpen(true);
        setToastMsg(fieldValidationErrors.firstName);
      } else if (!validationForms.lastName) {
        setToastOpen(true);
        setToastMsg(fieldValidationErrors.lastName);
      } else if (!validationForms.email) {
        setToastOpen(true);
        setToastMsg(fieldValidationErrors.email);
      } else if (!validationForms.password) {
        setToastOpen(true);
        setToastMsg(fieldValidationErrors.password);
      } else if (!validationForms.country) {
        setToastOpen(true);
        setToastMsg(fieldValidationErrors.country);
      } else if (!validationForms.city) {
        setToastOpen(true);
        setToastMsg(fieldValidationErrors.city);
      } else if (!validationForms.phoneCode) {
        setToastOpen(true);
        setToastMsg(fieldValidationErrors.phoneCode);
      } else if (!validationForms.phoneumber) {
        setToastOpen(true);
        setToastMsg(fieldValidationErrors.phoneumber);
      }

      setLoading(false)
    }
  };

  const RegisterApiCall = (obj) => {
    Commanservice.postLaravelApi("AuthController", obj).then((res) => {
      if (res["data"]["success"] === 1) {
        setToastOpen(true)
        setIsSuccess(true);
        setToastMsg(res.data.message);
        dispatch(getLoginDetailsData(res.data.data));
        guestToFavourite(res.data.data)
        guestToMember(res.data.data)
      } else {
        setToastOpen(true)
        setIsSuccess(false);
        setToastMsg(res.data.message);
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false)
    })
  }

  const guestToMember = (data) => {
    const guestObj = {
      a: "GuestToMember",
      store_id: selector.storeEntityId.mini_program_id,
      guest_id: RandomId,
      member_id: data.member_id,
      customer_name: "guest",
    };

    Commanservice.postLaravelApi("CartMaster", guestObj).then((guestObj) => {
      dispatch(isGuestIdLogin(true))
      loading = false;
      setLoading(false);
      if (!loading) {
        dispatch(isRegisterModalOpen(false))
        if (!selector.isProcessToCheckoutClick) {
          dispatch(storeSectionUrl("shopping-cart"))
          navigate("/shopping-cart")
        } else {
          navigate("/");
        }
      }
    }).catch(() => {
      loading = false;
      setLoading(false);
      if (!loading) {
        dispatch(isRegisterModalOpen(false))
        if (!selector.isProcessToCheckoutClick) {
          dispatch(storeSectionUrl("shopping-cart"))
          navigate("/shopping-cart")
        } else {
          navigate("/");
        }
      }
      setLoading(false)
    })
  }

  const loginClick = (value) => {
    dispatch(isLoginModalOpen(true))
    dispatch(isRegisterModalOpen(false))
    dispatch(storeForgotVar(false));
  };

  const guestToFavourite = (registerData) => {
    const obj = {
      "a": "GuestToFavoriteMember",
      "store_id": selector.storeEntityId.mini_program_id,
      "guest_id": RandomId,
      "member_id": registerData.member_id
    }

    Commanservice.postLaravelApi("FavouriteController", obj).then((res) => {
    }).catch(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <div id="register">
        <>
          <Modal show={selector.isRegisterModalOpen} className={`modal-xl ${isEmpty(otherLogo.image) == "" ? 'without_images' : ''}`} aria-labelledby="contained-modal-title-vcenter" id="register" centered keyboard={true} tabIndex="-1" aria-hidden="true">
            {loading && <Loader />}

            <form className=" h-100" id="registerForm" autoComplete="off">

              <div className="d-flex align-items-center h-100">
                {<div className="colse-icon" onClick={() => { if (selector.isCartLoginClick) { dispatch(isProcessToCheckoutClick(true)); }; if (selector.isChangePasswordLoginClick) { navigate("/") }; dispatch(isLoginModalOpen(false)); dispatch(isRegisterModalOpen(false)); }} aria-label="Close">
                  <i className="ic_remove"></i>
                </div>
                }
                <div className="login_info">
                  <div className={`login_info_inner   ${Object.keys(otherLogo).length === 0 && ""}`}>
                    {Object.keys(otherLogo).length > 0 && otherLogo.image !== "" && <div className="login_info_left bg-image" style={{ background: 'url("' + otherLogo.image + '") no-repeat center center/cover' }}>
                      {/* <div className="banner_img">
                        <React.Fragment>
                          <img src={otherLogo.image} className="h-100 w-100" alt=" preview" />
                        </React.Fragment>
                      </div> */}
                    </div>}
                    <div className="login_info_right">
                      <div className="login-field">
                        <div className="row">
                          <div className="col-12">
                            <div className="App-Logo mx-auto text-center">
                              {logoRegister.length > 0 &&
                                <>
                                  {logoRegister.map((L, i) => {
                                    return (
                                      <React.Fragment key={i}>
                                        {isEmpty(L.image) !== "" &&
                                          <>
                                            {L.logo_type === "HEADER" ? (
                                              <img alt="" src={L.image} className="mb-3" />
                                            ) : (
                                              ""
                                            )}
                                          </>

                                        }
                                      </React.Fragment>
                                    );
                                  })
                                  }
                                </>
                              }
                              <h5 className="main-title01 bodycontent-sub-title-size mt-3">SIGN UP </h5>
                            </div>
                          </div>

                          <div className="col-12 col-sm-6 mb-3">
                            <label className="fw-400 medium-small-title d-block mb-0 main-sub-title">First Name*</label>
                            <div className="d-flex align-items-center ">
                              <i className="ic_my_account input-icon"></i>
                              <input type="text" className="input-text alphabet" id="First Name" placeholder="Enter First Name"
                                value={firstName} onChange={(e) => {
                                  if (validateWithOnlyLetters(e.target.value)) {
                                    setFirstName(e.target.value);
                                  }
                                }} autoComplete="off" />
                            </div>
                          </div>

                          <div className="col-12 col-sm-6 mb-3">
                            <label className="fw-400 medium-small-title d-block mb-0 main-sub-title">Last Name*</label>
                            <div className="d-flex align-items-center ">
                              <i className=" ic_my_account input-icon"></i>
                              <input type="text" className="input-text alphabet" id="Last Name" placeholder="Enter Last Name"
                                value={lastName} onChange={(e) => {
                                  if (validateWithOnlyLetters(e.target.value)) {
                                    setLastName(e.target.value);
                                  }
                                }} autoComplete="off" />
                            </div>
                          </div>

                          <div className="col-12 col-sm-6 mb-3">
                            <label className="fw-400 medium-small-title d-block mb-0 main-sub-title">Email*</label>
                            <div className="d-flex align-items-center ">
                              <i className="ic_email input-icon"></i>
                              <input type="text" className="input-text" id="Email" placeholder="Email*" value={email} onChange={(e) => { setEmail(e.target.value); }} autoComplete="off" />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <label className="fw-400 medium-small-title d-block mb-0 main-sub-title">Password*</label>
                            <div className="d-flex align-items-center position-relative">
                              <i className="ic_password input-icon"></i>
                              <input
                                type={isHiddenPassword ? "password" : "text"} className="input-text Password1" id="Password" placeholder="Password*" value={password} onChange={(e) => { setPassword(e.target.value); }} autoComplete="new-password" />
                              <i onClick={() => { setIsHiddenPassword(!isHiddenPassword) }} className={`${isHiddenPassword ? "ic_eye_close" : "ic_eye_open"}  input-eye-icon `}></i>
                            </div>
                          </div>

                          <div className="col-12 col-sm-6 mb-3">
                            <label className="fw-400 medium-small-title d-block mb-0 main-sub-title">City*</label>
                            <div className="d-flex align-items-center ">
                              <i className="ic_signpost_split input-icon"></i>
                              <input type="text" className="input-text" id="Email" placeholder="Enter your City" value={city} onChange={(e) => {
                                if (validateWithOnlyLetters(e.target.value)) {
                                  setCity(e.target.value);
                                }
                              }} autoComplete="off" />
                            </div>
                          </div>

                          <div className="col-12 col-sm-6 mb-3">
                            <label className="fw-400 medium-small-title d-block mb-0 main-sub-title">State</label>
                            <div className="d-flex align-items-center ">
                              <i className="ic_signpost input-icon"></i>
                              <input type="text" className="input-text" id="Email" placeholder="Enter your State" value={state} onChange={(e) => {
                                if (validateWithOnlyLetters(e.target.value)) {
                                  setState(e.target.value);
                                }
                              }} autoComplete="off" />
                            </div>
                          </div>

                          <div className="col-12 mb-3  custome-select">
                            <label className="fw-400 medium-small-title d-block mb-0 main-sub-title">Country*</label>
                            <div className="d-flex align-items-center ">
                              <i className="ic_country input-icon"></i>
                              <Select
                                className="w-100"
                                options={getCountry}
                                placeholder="Select Your Country"
                                value={country}
                                onChange={(e) => { ChangesCountry(e) }}
                                isSearchable={true}
                                isMulti={false}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              />
                              {/* <Form.Select className="input-text" value={countryShortCode} onChange={(e) => ChangesCountry(e)}>
                                <option value="">Select your Country</option>
                                {getCountry.length > 0 && getCountry.map((item, i) => {
                                  return (<option value={item.sortname} key={i}>{item.name}</option>)
                                })}
                              </Form.Select> */}
                              {/* <input type="text" className="input-text" id="Email" placeholder="Select your Country" value={email} onChange={(e) => { setEmail(e.target.value); }} autoComplete="off" /> */}
                            </div>
                          </div>

                          <div className="col-12 mb-3 custome-select">
                            <label className="fw-400 medium-small-title d-block mb-0 main-sub-title">Phone Code/Mobile Number*</label>
                            <div className="input-group">
                              <span style={{ width: '150px' }}>
                                <Select
                                  options={phoneCodeDataDrp}
                                  placeholder="Phone Code"
                                  value={phoneCode}
                                  onChange={(e) => { ChangesPhoneCode(e) }}
                                  isSearchable={true}
                                  isMulti={false}
                                  menuPortalTarget={document.body}
                                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                              </span>
                              {/* <Form.Select style={{ width: '120px' }} className="input-text" value={phoneCode} onChange={(e) => ChangesPhoneCode(e)}>
                                <option value="">Phone Code</option>
                                {getCountry.length > 0 && getCountry.map((item, i) => {
                                  return (<option value={item.phonecode} key={i}>{item.phonecode} - {item.name}</option>)
                                })}
                              </Form.Select> */}
                              {/* {isEmpty(phoneCode) !== "" ?
                                <i className="input-icon">{phoneCode}</i> :
                                <i className="ic_telephone input-icon"></i>
                              } */}
                              <input style={{ width: '0' }} type="text" maxLength="14" minLength={"8"} className="input-text form-control" id="Email" placeholder="Enter your Mobile Number" value={phoneumber} onChange={(e) => { if (onlyNumbers(e.target.value)) { setPhoneNumber(e.target.value); } }} autoComplete="off" />
                            </div>
                          </div>

                          <div className="col-12 mb-3">
                            <label className="fw-400 medium-small-title d-block mb-0 main-sub-title">Select Gender</label>
                            <div className="d-flex align-items-center ">
                              <i className="ic_email input-icon"></i>
                              <Form.Select className="input-text" value={gender} onChange={(e) => setGender(e.target.value)}>
                                <option value="">Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Form.Select>
                              {/* <input type="text" className="input-text" id="Email" placeholder="Enter your Mobile Number" value={email} onChange={(e) => { setEmail(e.target.value); }} autoComplete="off" /> */}
                            </div>
                          </div>


                          <div className="col-12 text-center">
                            <button type="button" disabled={loading} onClick={() => Registers()} className="btn button-thamebalck"> CREATE ACCOUNT </button>
                          </div>

                          <div className="col-12 text-center main-sub-title bodycontent-text-size mt-3">
                            Already have an account?
                            <span onClick={() => loginClick()} className="ms-1 main-sub-title cursor-pointer fw-500 text-decoration-underline" aria-label="Close">Sign In</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <ToastComponent
              show={toastShow}
              success={isSuccess}
              onClose={() => setToastOpen(false)}
              header={isSuccess ? "Success" : "Error"}
              toastBody={<div>{toastMsg}</div>}
            />
          </Modal>

          {selector.isLoginModalOpen ? (
            <Login
              logologin={selector.storeGetLogo}
              isNavigateToHome={props.isNavigateToHome}
              isCloseButtonHide={props.isCloseButtonHide}
              isCartLogin={false}
            />
          ) : (
            ""
          )}
        </>
      </div>
    </>
  );
};

export default SignUp;
