import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "../src/App";
import { Provider } from "react-redux";
import store from "../src/Redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

const persistStore1 = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("stoon"));

root.render(
  <>
    <Provider store={store}>
      <PersistGate persistor={persistStore1}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </>
);

reportWebVitals()

