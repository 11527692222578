const initialState = {
  count: 0,
  storeFavIconCount: 0,
  isRegisterModalOpen: false,
  isLoginModalOpen: false,
  isGuestIdLogin: false,
  data: {},
  isforgot: false,
  storeSectionUrl: "",
  isProcessToCheckoutClick: false,
  storeEntityId: {},
  storeGetLogo: [],
  openGetCartNavbar: false,
  storeGetCartData: [],
  storeGetCartPrice: 0,
  isCartLoginClick: false,
  isChangePasswordLoginClick: false,
  isJewelDIY: false,
  isDiamoDIY: false,
  storeFilterValueName: "",
  storeSubNavbarName: "",
  storeCurrency: "",
  storeColorData: {},
  getJourneyCatalogueId: [],
  activeTabName: "",
  B2C_Diamond: {},
  diamondParamsObject: {},
  jewelleryRingObj: {},
  ringAndDiamondData: {},
  diyDiamondTabName: "",
  activeMenuName: "",
};

const Reducer = (state = initialState, action) => {

  switch (action.type) {
    case "countOfCartItems": {
      return {
        ...state,
        count: action.count,
      };
    }

    case "isLoginModalOpen": {
      state.isLoginModalOpen = action.isLoginModalOpen;
      return {
        ...state,
        isLoginModalOpen: state.isLoginModalOpen,
      };
    }
    case "isRegisterModalOpen": {
      state.isRegisterModalOpen = action.isRegisterModalOpen;
      return {
        ...state,
        isRegisterModalOpen: state.isRegisterModalOpen,
      };
    }

    case "storeSectionUrl": {
      state.storeSectionUrl = action.storeSectionUrl;
      return {
        ...state,
        storeSectionUrl: state.storeSectionUrl,
      };
    }


    case "storeCurrency": {
      state.storeCurrency = action.storeCurrency;
      return {
        ...state,
        storeCurrency: state.storeCurrency,
      };
    }

    case "getLoginDetails": {
      state.data = action.data;
      return {
        ...state,
        data: state.data,
      };
    }

    case "storeColorData": {
      state.storeColorData = action.storeColorData;
      return {
        ...state,
        storeColorData: state.storeColorData,
      };
    }

    case "isCartLoginClick": {
      state.isCartLoginClick = action.isCartLoginClick;
      return {
        ...state,
        isCartLoginClick: state.isCartLoginClick,
      };
    }

    case "isChangePasswordLoginClick": {
      state.isChangePasswordLoginClick = action.isChangePasswordLoginClick;
      return {
        ...state,
        isChangePasswordLoginClick: state.isChangePasswordLoginClick,
      };
    }

    case "storeGetCartData": {
      state.storeGetCartData = action.storeGetCartData;
      return {
        ...state,
        storeGetCartData: state.storeGetCartData,
      };
    }

    case "storeSubNavbarName": {
      state.storeSubNavbarName = action.storeSubNavbarName;
      return {
        ...state,
        storeSubNavbarName: state.storeSubNavbarName,
      };
    }

    case "storeGetCartPrice": {
      state.storeGetCartPrice = action.storeGetCartPrice;
      return {
        ...state,
        storeGetCartPrice: state.storeGetCartPrice,
      };
    }

    case "isJewelDIY": {
      state.isJewelDIY = action.isJewelDIY;
      return {
        ...state,
        isJewelDIY: state.isJewelDIY,
      };
    }

    case "storeFilterValueName": {
      state.storeFilterValueName = action.storeFilterValueName;
      return {
        ...state,
        storeFilterValueName: state.storeFilterValueName,
      };
    }

    case "isDiamoDIY": {
      state.isDiamoDIY = action.isDiamoDIY;
      return {
        ...state,
        isDiamoDIY: state.isDiamoDIY,
      };
    }

    case "isGuestIdLogin": {
      state.isGuestIdLogin = action.isGuestIdLogin;
      return {
        ...state,
        isGuestIdLogin: state.isGuestIdLogin,
      };
    }

    case "storeForgotVar": {
      state.isforgot = action.value;
      return {
        ...state,
        value: state.isforgot,
      };
    }
    case "storeFavCount": {
      state.storeFavIconCount = action.storeFavCount;
      return {
        ...state,
        storeFavCount: state.storeFavIconCount,
      };
    }

    case "storeEntityId": {
      state.storeEntityId = action.storeEntityId;
      return {
        ...state,
        storeEntityId: state.storeEntityId,
      };
    }

    case "isProcessToCheckoutClick": {
      state.isProcessToCheckoutClick = action.isProcessToCheckoutClick;
      return {
        ...state,
        isProcessToCheckoutClick: state.isProcessToCheckoutClick,
      };
    }

    case "storeGetLogo": {
      state.storeGetLogo = action.storeGetLogo;
      return {
        ...state,
        storeGetLogo: state.storeGetLogo,
      };
    }

    case "openGetCartNavbar": {
      state.openGetCartNavbar = action.openGetCartNavbar;
      return {
        ...state,
        openGetCartNavbar: state.openGetCartNavbar,
      };
    }

    case "getJourneyCatalogueId": {
      state.getJourneyCatalogueId = action.getJourneyCatalogueId;
      return {
        ...state,
        getJourneyCatalogueId: state.getJourneyCatalogueId,
      };
    }

    case "TAB_SELECTION": {
      return {
        ...state,
        activeTabName: action.payload,
      };
    }

    case "DIAMOND_SELECT": {
      return {
        ...state,
        B2C_Diamond: action.payload,
      };
    }

    case "SET_DIAMOND_PARAMS": {
      return {
        ...state,
        diamondParamsObject: action.payload,
      };
    }

    case "JEWEL_RING_SELECT": {
      return {
        ...state,
        jewelleryRingObj: action.payload,
      };
    }

    case "SELECT_RING_AND_DIAMOND": {
      return {
        ...state,
        ringAndDiamondData: action.payload,
      };
    }

    case "EDIT_DIY_DIAMOND": {
      return {
        ...state,
        diyDiamondTabName: action.payload,
      };
    }

    case "SET_MENU_NAME": {
      return {
        ...state,
        activeMenuName: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default Reducer;
