import axios from "axios";
export var imageUrl;
export var domain;

// STAGING
// export const imageUrl = "https://apistaging-store.upqor.com";
// const baseUrlLaravel = "https://apistaging-store.upqor.com/api/call";
// const baseUrl2 = "https://apistaging.upqor.com/call";
// export const domain = window.location.origin;

// UAT
// export const imageUrl = "https://apiuat-store.upqor.com"
// const baseUrlLaravel = "https://apiuat-store.upqor.com/api/call";
// const baseUrl2 = "https://apiuat.upqor.com/call";
// export const domain = window.location.origin;

// LIVE // ECOM
// export const imageUrl = "https://api-store.upqor.com";
// const baseUrlLaravel = "https://api-store.upqor.com/api/call";
// const baseUrl2 = "https://api.upqor.com/call";
// export const domain = window.location.origin;

// LOCAL5
// export const imageUrl = process.env.REACT_APP_IMAGEURL
// const baseUrlLaravel = process.env.REACT_APP_BASEURLLARAVEL
// const baseUrl2 = process.env.REACT_APP_BASEURL2;
// // export const domain = "https://b2c.onlynaturaldiamonds.com";
// // export const domain = "https://zurah.com"
// export const domain = process.env.REACT_APP_ENV !== "local" ? window.location.origin : process.env.REACT_APP_DOMAIN

var baseUrlLaravel = "";
var baseUrl2 = "";
if ((window.location.origin).includes('localhost')) {
  imageUrl = "http://192.168.84.59:8080";
  baseUrlLaravel = "http://192.168.84.59:8080/api/call";
  baseUrl2 = "http://192.168.84.59/sit-ci-api/call";
  domain = 'https://uat.diamondsforapurpose.com';

  // imageUrl = "https://apistaging-ecom-store.upqor.com"
  // baseUrlLaravel = "https://apistaging-ecom-store.upqor.com/api/call";
  // baseUrl2 = "https://apistaging-ecom.upqor.com/call";
  // domain = 'https://staging.diamondsforapurpose.com';
}
else if ((window.location.origin).includes('staging')) {
  imageUrl = "https://apistaging-ecom-store.upqor.com"
  baseUrlLaravel = "https://apistaging-ecom-store.upqor.com/api/call";
  baseUrl2 = "https://apistaging-ecom.upqor.com/call";
  domain = window.location.origin;
}
else if ((window.location.origin).includes('uat')) {
  imageUrl = "https://apiuat-ecom-store.upqor.com"
  baseUrlLaravel = "https://apiuat-ecom-store.upqor.com/api/call";
  baseUrl2 = "https://apiuat-ecom.upqor.com/call";
  domain = window.location.origin;
}
else {
  imageUrl = "https://api-ecom-store.upqor.com"
  baseUrlLaravel = "https://api-ecom-store.upqor.com/api/call";
  baseUrl2 = "https://api-ecom.upqor.com/call";
  domain = window.location.origin;
}

class Commanservice {

  postLaravelApi(controller, obj) {
    return axios.post(baseUrlLaravel + "/" + controller, obj);
  }

  postApi(controller, obj, signal) {
    if (signal === undefined) {
      return axios.post(baseUrl2 + "/" + controller, obj);
    } else {
      return axios.post(baseUrl2 + "/" + controller, obj, { signal: signal });
    }
  }

  obj_json(obj, value) {
    const data = [];
    data["a"] = value;
    for (const pair of obj) {
      data[pair[0]] = pair[1];
    }
    const arrayToString = JSON.stringify(Object.assign({}, data));
    return arrayToString;
  }

}

export default new Commanservice();
