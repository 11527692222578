import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const InquiryRoute = ({ children }) => {

    const selector = useSelector(state => state)
    if (isEmpty(selector.storeEntityId.transaction_type) !== "") {
        if (selector.storeEntityId.transaction_type !== "CHECKOUT") {
            return <Navigate to="/" replace />;
        }
    }
    return children;
};
export default InquiryRoute;