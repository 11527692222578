import React from "react";
import "./loader.scss"
import CircleLoader from "react-spinners/ClipLoader";

const Loader = () => {
  return (
    <section id="loader">
      <div className="loader">
        <CircleLoader color="#fff" size={100} />
      </div>
        {/* <div className="loader_diamond">
          <div className="wrap rotor-x">
              <div className="wrap rotor-y">
                <div className="wrap rotor-z">
                  <div className="triangle bottom face-1"></div>
                  <div className="triangle bottom face-2"></div>
                  <div className="triangle bottom face-3"></div>
                  <div className="triangle bottom face-4"></div>
                  <div className="triangle bottom face-5"></div>
                  <div className="triangle bottom face-6"></div>
                  <div className="triangle bottom face-7"></div>
                  <div className="triangle bottom face-8"></div>
                  
                  <div className="triangle middle-bottom face-1"></div>
                  <div className="triangle middle-bottom face-2"></div>
                  <div className="triangle middle-bottom face-3"></div>
                  <div className="triangle middle-bottom face-4"></div>
                  <div className="triangle middle-bottom face-5"></div>
                  <div className="triangle middle-bottom face-6"></div>
                  <div className="triangle middle-bottom face-7"></div>
                  <div className="triangle middle-bottom face-8"></div>
                  
                  <div className="triangle middle-top face-1"></div>
                  <div className="triangle middle-top face-2"></div>
                  <div className="triangle middle-top face-3"></div>
                  <div className="triangle middle-top face-4"></div>
                  <div className="triangle middle-top face-5"></div>
                  <div className="triangle middle-top face-6"></div>
                  <div className="triangle middle-top face-7"></div>
                  <div className="triangle middle-top face-8"></div>

                  <div className="triangle up face-1"></div>
                  <div className="triangle up face-2"></div>
                  <div className="triangle up face-3"></div>
                  <div className="triangle up face-4"></div>
                  <div className="triangle up face-5"></div>
                  <div className="triangle up face-6"></div>
                  <div className="triangle up face-7"></div>
                  <div className="triangle up face-8"></div>

                </div>
              </div>
          </div>
        </div> */}
    </section>
  );
};

export default Loader;
