import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./header.scss";
import Commanservice from "../../../CommanService/commanService";
import { changeUrl, isEmpty, jewelVertical, numberWithCommas, RandomId, splicaArrayIntoColumns, jewelSessionData, diamoSessionData, looseSessionData, storeType } from "../../CommanFunctions/commanFunctions";
import { connect } from "react-redux";
import ToastComponent from "../../CommanUIComp/Toast/toast";
import Login from "../../Profile/SignIn/signIn";
import Register from "../../Profile/SignUp/signUp";
import Loader from "../../CommanUIComp/Loader/loader";
import { Container, Offcanvas, Form } from 'react-bootstrap';
import favLogo from "../../../Assets/favicon.ico";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { diamondParamsAction, diamondSelectAction, handleMenuNameAction, ringAndDiamondAction, selectRingAction } from "../../../Redux/action";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      toastShow: false,
      toastMsg: "",
      isSuccess: false,
      openModal: false,
      getCurrencyDropDownData: [],
      navigationIsUpdated: false,
      storeObject: props.storeEntityId,
      onceUpdated: false,
      storeHeaderData: {},
      storeHeaderLogo: [],
      onceUpdatedLogo: false,
      storeVerticalCode: {},
      responsiveIcon: false,
      Navbartoggle: false
    };
  }

  componentDidMount() {
    if (!window.location.pathname.includes('start-with-diamond')) {
      this.props.initialDiyState()
    }
    if (this.state.Navbartoggle == true) {
      document.getElementById('navbartoggler').classList.add('collapsed')
    } else {
      document.getElementById('navbartoggler').classList.remove('collapsed')
    }
    window.addEventListener('scroll', () => {
      const navElement = document.getElementsByTagName("nav")
      if (document.documentElement.scrollTop >= 30) {
        navElement[0].classList.add("fixed-header")
        navElement[0].children[0].classList.add("visible-title")
        document.getElementsByClassName("user-icon")[0].classList.add("d-flex")
        navElement[0].style.boxShadow = "1px 1px 10px rgb(206 206 206 / 40%)";
        document.getElementsByClassName("fixed-header")[0].style.opacity = "1"
      } else {
        if (isEmpty(document.getElementsByClassName("fixed-header")[0]) !== "") {
          document.getElementsByClassName("fixed-header")[0].style.opacity = "1"
        }
        navElement[0].style.opacity = "1"
        navElement[0].classList.remove("fixed-header")
        navElement[0].children[0].classList.remove("visible-title")
        navElement[0].style.boxShadow = "none";
        document.getElementsByClassName("user-icon")[0].classList.remove("d-flex")
      }
    });

    this.oneTimeApiCall()

    let sum = 0;
    this.props.storeGetCartData.length > 0 && this.props.storeGetCartData.map((e) => {
      sum += (e.item_price);
      return e
    })

    this.props.getStoreGetCartPrice(sum)
  }

  componentDidUpdate(props) {

    if (!window.location.pathname.includes('start-with-diamond')) {
      this.props.initialDiyState()
    }

    this.oneTimeApiCall()
    if (this.state.Navbartoggle == true) {
      document.getElementById('navbartoggler').classList.add('collapsed')
    } else {
      document.getElementById('navbartoggler').classList.remove('collapsed')
    }

    let sum = 0;
    this.props.storeGetCartData.length > 0 && this.props.storeGetCartData.map((e) => {
      sum += (isEmpty(e.item_price));
      return e
    })

    this.props.getStoreGetCartPrice(sum)
  }

  oneTimeApiCall = () => {
    if (Object.keys(isEmpty(this.props.storeEntityId)).length > 0 && this.props.isStoreApiCalling === true) {
      if (!this.state.onceUpdated) {
        this.props.setLoder(true)
        this.setState({
          // loader: true,
          onceUpdated: true
        });

        if (sessionStorage.getItem("megaMenu") === null) {
          this.getHeaderSectionData()
        }

        if (sessionStorage.getItem("megaMenu") !== null) {
          this.setHeaderLogo(JSON.parse(sessionStorage.getItem("megaMenu")))
          this.setState({
            getCurrencyDropDownData: isEmpty(JSON.parse(sessionStorage.getItem("megaMenu")).currency_data) !== "" ? JSON.parse(sessionStorage.getItem("megaMenu")).currency_data : [],
            storeHeaderData: sessionStorage.getItem("megaMenu") === null ? {} : Object.keys(JSON.parse(sessionStorage.getItem("megaMenu"))).length > 0 ? JSON.parse(sessionStorage.getItem("megaMenu")) : {},
            loader: false,
          })
          this.props.setLoder(false)
        }
      }
    }
  }

  urlClick(s1, event, e, displayName) {
    if (displayName) {
      this.props.setMenuName(displayName);
      this.props.getSectionUrl(isEmpty(e.product_vertical_name));
    }

    if (window.location.pathname.includes('start-with-diamond')) {
      this.props.initialDiyState()
    }

    if (s1.url) {
      if (window.location.pathname === s1.url) {
        event.stopPropagation();
        event.preventDefault();
        this.props.navigate("/-");
      }
      sessionStorage.setItem("storeUrl", (s1.url))
      sessionStorage.removeItem("collection_id");
      sessionStorage.removeItem("finish_type");
      if (sessionStorage.getItem("collection") !== null) {
        sessionStorage.removeItem("collection")
      }

      jewelSessionData()
      diamoSessionData()
      looseSessionData()

      this.props.getStoreSubNavbarName(isEmpty(s1.title) !== "" ? s1.title.toUpperCase() + isEmpty(s1.vertical_code) : "");
      this.props.setStoreFilterValueName(isEmpty(s1.title) !== "" ? s1.title.toUpperCase() : "");

      if (s1.url.includes("start-with-a-setting")) {
        this.props.setIsDiamoDIY(false);
        this.props.setIsJewelDIY(true);

      } else if (s1.url.includes("start-with-a-diamond")) {
        this.props.setIsDiamoDIY(true);
        this.props.setIsJewelDIY(false);
      } else {
        this.props.setIsDiamoDIY(false);
        this.props.setIsJewelDIY(false);
      }
      this.props.getSectionUrl(isEmpty(e.product_vertical_name));
    } else if (e.router_link) {

      this.props.navigate("/-");

      sessionStorage.setItem("storeUrl", (e.router_link))
      sessionStorage.removeItem("collection_id");
      sessionStorage.removeItem("finish_type");
      if (sessionStorage.getItem("collection") !== null) {
        sessionStorage.removeItem("collection")
      }
      this.props.getSectionUrl(isEmpty(e.product_vertical_name));
    }
  };

  getHeaderSectionData() {
    var isTrue = false
    const obj = {
      "a": "GetHomeNavigation",
      "store_id": isEmpty(this.props.storeEntityId.mini_program_id),
      type: storeType
    }

    Commanservice.postLaravelApi("NavigationMegamenu", obj).then((res) => {
      if (res.data.success === 1) {
        let data = res.data.data
        isTrue = true

        if (Object.keys(data).length > 0) {
          if (isEmpty(data.navigation_data) !== "") {
            var sub_diy_data = []
            const navigationArr = [...data.navigation_data]
            if (navigationArr.length > 0) {
              navigationArr.map((e) => {
                e.isOpen = false
                isEmpty(e.sub_menu) !== "" && e.sub_menu.length > 0 && e.sub_menu.map((s) => {
                  const detailArr = []
                  if (isEmpty(s.detaills) !== "" && s.detaills.length > 0) {

                    s.detaills.map((d) => {
                      if (isEmpty(d.logic_code) === "size_group") {
                        d.logic_code = 'carat'
                      }
                      if ((isEmpty(d.logic_code)).includes('_') === true) {
                        var output = (isEmpty(d.logic_code).split('_')).pop();
                        d.titles = isEmpty(d.title) !== "" ? d.title.replaceAll(' ', '-') : "";
                        d['router_link'] = isEmpty(e.router_link) + '/' + output + '/' + (isEmpty(d.titles) !== "" ? changeUrl(d.titles) : "")
                      } else {
                        d.titles = isEmpty(d.title) !== "" ? d.title.replaceAll(' ', '-') : "";
                        d['router_link'] = isEmpty(e.router_link) + '/' + isEmpty(d.logic_code) + '/' + (isEmpty(d.titles) !== "" ? (d.titles.split(" ").join("-").toLowerCase()) : "")
                      }

                      detailArr.push({
                        title: d.title,
                        code: isEmpty(d.logic_code),
                        icon: isEmpty(d.icon),
                        url: d['router_link'],
                        vertical_code: isEmpty(d.vertical_code),
                      })
                      return s
                    })
                    s.detailArr = detailArr
                  } else {
                    if (isEmpty(s.sub_category) === "JCYDI") {
                      detailArr.push({
                        title: "Start With Setting",
                        code: "",
                        icon: "",
                        url: isEmpty(e.router_link) + "/start-with-a-setting",
                        vertical_code: isEmpty(s.product_vertical),
                      }
                      )
                      s.detailArr = detailArr
                    }
                    else {
                      s.detailArr = []
                    }

                    if (s.product_vertical == 'DIY') {
                      sub_diy_data.push({
                        title: s.display_name,
                        code: "",
                        icon: "",
                        url: isEmpty(e.router_link),
                        vertical_code: 'DIY',
                      })
                      e.sub_menu[0].detailArr = sub_diy_data
                    }
                    // else if (isEmpty(s.sub_category === "LGFYDI") || isEmpty(s.sub_category) === "LFYDI" || isEmpty(s.sub_category) === "GFYDI") {
                    //   detailArr.push({
                    //     title: (s.sub_category) === "LGFYDI" ? "START WITH LAB GROWN" :
                    //       (s.sub_category) === "LFYDI" ? "START WITH LOOSE DIAMOND" : (s.sub_category) === "GFYDI" ? "START WITH GEM STONE" : "",
                    //     code: "",
                    //     icon: "",
                    //     url: isEmpty(e.router_link),
                    //     vertical_code: isEmpty(s.product_vertical),
                    //   })
                    //   s.detailArr = detailArr
                    // }
                  }
                  return s
                })
                return e
              })
            }
          }

          if (isEmpty(data.currency_data) !== "") {
            const arr1 = data.currency_data
            if (arr1.length > 0) {
              if (arr1.length > 1) {
                this.setState({
                  getCurrencyDropDownData: arr1
                })
                const arr2 = arr1.filter((e) => isEmpty(e?.is_default) === 1)
                if (arr2.length > 0) {
                  if (this.props.storeCurrency === "") {
                    this.updateCartCurrency(isEmpty(arr2[0].mp_store_price))
                    this.props.getStoreCurrency(isEmpty(arr2[0].mp_store_price))
                  } else {
                    this.updateCartCurrency(isEmpty(this.props.storeCurrency))
                    this.props.getStoreCurrency(isEmpty(this.props.storeCurrency))
                  }
                } else {
                  if (this.props.storeCurrency === "") {
                    this.updateCartCurrency(isEmpty(arr1[0].mp_store_price))
                    this.props.getStoreCurrency(isEmpty(arr1[0].mp_store_price))
                  } else {
                    this.updateCartCurrency(isEmpty(this.props.storeCurrency))
                    this.props.getStoreCurrency(isEmpty(this.props.storeCurrency))
                  }
                }
              } else {
                this.setState({
                  getCurrencyDropDownData: []
                })
                // if (this.props.storeCurrency === "") {
                this.props.getStoreCurrency(isEmpty(arr1[0].mp_store_price))
                // } else {
                //   this.props.getStoreCurrency(isEmpty(arr1[0].mp_store_price))
                // }
              }
            }
          } else {
            this.setState({
              getCurrencyDropDownData: []
            })
            this.props.getStoreCurrency(this.props.storeCurrency)
          }

          this.setState({
            storeHeaderData: res.data.data,
            loader: false
          })
          this.props.setLoder(false)
        } else {
          this.props.getStoreCurrency(this.props.storeCurrency)
          this.setState({
            loader: false
          })
          this.props.setLoder(false)
          this.setState({
            getCurrencyDropDownData: []
          })
        }
        sessionStorage.setItem("megaMenu", JSON.stringify(res.data.data))
        this.setHeaderLogo(data)
      } else {
        this.props.getStoreCurrency(this.props.storeCurrency)
        this.setState({
          toastShow: true,
          isSuccess: false,
          toastMsg: res.data.message,
          loader: false
        })
        this.props.setLoder(false)
      }
    }).catch(() => {
      if (sessionStorage.getItem("megaMenu") === null && isTrue === false) {
        this.props.getStoreCurrency(this.props.storeCurrency)
      }
      this.setState({
        loader: false
      })
      this.props.setLoder(false)
    })
  }

  setHeaderLogo(data) {
    const getFavLogo = document.getElementById("favicon")
    if (Object.keys(data).length > 0) {
      // if (!onceUpdatedLogo) {
      this.props.getStoreGetLogo(isEmpty(data.logo_data))
      const faviconLogo = isEmpty(data.logo_data) !== "" ? data.logo_data.filter((h) => isEmpty(h.logo_type) === "FAVICON") : []
      const filterLogo = isEmpty(data.logo_data) !== "" ? data.logo_data.filter((h) => isEmpty(h.logo_type) === "HEADER") : []

      var link = document.querySelector("link[rel~='icon']");
      if (faviconLogo.length > 0) {
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.head.appendChild(link);
        }
        if (favLogo.length > 0) {
          link.href = favLogo[0].image;
        }
      }
      else {
        link.href = favLogo;
      }
      this.setState({
        storeHeaderLogo: filterLogo,
        onceUpdatedLogo: true
      })
      // }
    } else {
      getFavLogo['href'] = favLogo
      this.props.getStoreGetLogo([])
      this.setState({
        storeHeaderLogo: [],
      })
    }
  }

  logout() {
    this.props.getLoginData({});
    this.props.getCountNo(0);
    this.props.getStoreFavIcon(0);
    this.props.getisProcessToCheckoutClick(true)
    this.props.setIsLoginOpenModal(false)
    this.props.setIsRegisterModalOpen(false)
    this.props.setisGuestIdLogin(false)
    this.props.navigate("/")
  }

  redirectToLogin() {
    this.props.getIsForgot(false);
    this.setState({
      loader: true,
    });
    setTimeout(() => {
      this.setState({
        loader: false,
      });
    }, 1000);
  }

  loginClick(value) {
    this.props.setIsLoginOpenModal(true)
    this.props.setIsRegisterModalOpen(false)
    this.props.getIsForgot(false);
  }

  regiterClick(value) {
    this.props.setIsLoginOpenModal(false)
    this.props.setIsRegisterModalOpen(true)
  }

  responIconOpen = () => {
    this.setState({
      responsiveIcon: true,
    });
  }

  responIconClose = () => {
    this.setState({
      responsiveIcon: false,
    });
  }

  updateCartCurrency = (e, isChange) => {
    this.setState({
      loader: true
    })
    const obj = {
      a: "updateCartCurrency",
      store_id: isEmpty(this.props.storeEntityId.mini_program_id),
      member_id: Object.keys(isEmpty(this.props.loginData)).length > 0 ? isEmpty(this.props.loginData.member_id) : RandomId,
      new_currency: isEmpty(e)
    }

    Commanservice.postLaravelApi("CartMaster ", obj).then((res) => {
      if (res.data.success === 1) {
        this.setState({
          loader: false
        })
        if (isChange === "onChange") {
          setTimeout(() => {
            window.location.reload()
          }, 100)
        }
      } else {
        this.setState({
          toastShow: true,
          isSuccess: false,
          toastMsg: isEmpty(res.data.message),
          loader: false
        })
      }
    }).catch(() => {
      this.setState({
        loader: false
      })
    })
  }

  otherUrl(e, event) {
    if (e.menu_name) {
      this.props.setMenuName(e.menu_name);
    }
    if (window.location.pathname === isEmpty(e.router_link)) {
      event.stopPropagation()
      event.preventDefault()
      this.props.navigate("/-")
    }

    sessionStorage.setItem("storeUrl", isEmpty(e.router_link))
    sessionStorage.removeItem("collection_id");
    sessionStorage.removeItem("finish_type");
    if (sessionStorage.getItem("collection") !== null) {
      sessionStorage.removeItem("collection")
    }
    jewelSessionData()
    this.props.getStoreSubNavbarName("");
    this.props.setIsDiamoDIY(false);
    this.props.setIsJewelDIY(false);

    if (isEmpty(e.product_vertical_name) === "OTHER") {
      this.props.getSectionUrl(isEmpty(e.menu_name))
    } else {
      this.props.getSectionUrl(isEmpty(e.product_vertical_name));
    }
  }

  removeItems(data, index) {
    this.setState({
      loader: true
    })
    const removeCart = {
      a: "removeCart",
      member_id: Object.keys(isEmpty(this.props.loginData)).length > 0 ? isEmpty(this.props.loginData.member_id) : RandomId,
      store_id: isEmpty(this.props.storeEntityId.mini_program_id),
      unique_id: data[0].unique_id,
    };
    Commanservice.postLaravelApi("CartMaster", removeCart)
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            toastShow: true,
            isSuccess: true,
            toastMsg: res.data.message,
            loader: false
          })
          this.getCartApi(this.props.storeEntityId, true)
        } else {
          this.setState({
            toastShow: true,
            isSuccess: false,
            toastMsg: res.data.message,
            loader: false
          })
          this.getCountApi()
        }
        document.querySelector("body").scrollTo(0, 0);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        // getCountApi()
        this.setState({
          toastShow: true,
          isSuccess: false,
          toastMsg: error.message,
          loader: false
        })
        this.getCountApi()
      });

  }

  getCartApi(data, isRemoveApiCall) {
    this.setState({ loader: true })
    const obj = {
      a: "getCart",
      origin: data.cmp_origin,
      store_id: data.mini_program_id,
      user_id: Object.keys(isEmpty(this.props.loginData)).length > 0 ? isEmpty(this.props.loginData.member_id) : RandomId,
      customer_name: Object.keys(isEmpty(this.props.loginData)).length > 0 ? this.props.loginData.first_name : "guest",
      tenant_id: (data.tenant_id),
      entity_id: (data.entity_id),
      per_page: "0",
      secret_key: data.secret_key,
      number: "0",
      store_type: storeType
    };

    Commanservice.postLaravelApi("CartMaster", obj).then((res) => {
      if (res.data.success == 1) {
        let cartData = res.data.data
        this.props.getStoreGetCartData(cartData)
        this.getCountApi()
        this.setState({
          toastShow: true,
          isSuccess: true,
          toastMsg: res.data.message,
          loader: false
        })
      } else {
        this.getCountApi()
        this.setState({
          toastShow: true,
          isSuccess: false,
          toastMsg: res.data.message,
          loader: false
        })
      }
    }).catch((error) => {
      this.getCountApi()
      this.setState({
        toastShow: true,
        isSuccess: false,
        toastMsg: error.message,
        loader: false
      })
    })
  }

  getCountApi() {
    this.setState({ loader: true })
    const getCount = {
      "a": "get_count",
      "store_id": isEmpty(this.props.storeEntityId.mini_program_id),
      "user_id": Object.keys(isEmpty(this.props.loginData)).length > 0 ? isEmpty(this.props.loginData.member_id) : RandomId,
    }
    Commanservice.postLaravelApi("CartMaster", getCount).then((res) => {
      if (res.data.success == 1) {
        const data = res.data.data
        this.props.getCountOfCartItems(isEmpty(data.cart_count) !== "" ? data.cart_count : 0)
        this.props.storeFavCount(isEmpty(data.favourite_count) !== "" ? data.favourite_count : 0)
        this.setState({
          loader: false
        })
      } else {
        this.props.getCountOfCartItems(0)
        this.props.storeFavCount(0)
        this.setState({
          loader: false
        })
      }
    }).catch((error) => {
      this.props.getCountOfCartItems(0)
      this.props.storeFavCount(0)
      this.setState({
        loader: false
      })
    })
  }

  NavbarOff() {
    this.setState({ Navbartoggle: false })
  }

  NavbarToggle() {
    if (this.state.Navbartoggle == true) {
      this.setState({ Navbartoggle: false })
    } else {
      this.setState({ Navbartoggle: true })
    }
  }

  render() {
    return (
      <React.Fragment>
        <header id="header">
          {this.state.loader && <Loader />}

          <section className="bg_top_header_color ">
            <div className="container">
              <div className="top-bar-row">
                <div className="top-bar-col">
                  {isEmpty(this.state.storeHeaderData) && Object.keys(this.state.storeHeaderData).length > 0 && isEmpty(this.state.storeHeaderData?.contact_data) && this.state.storeHeaderData?.contact_data.length > 0 && this.state.storeHeaderData?.contact_data.map((e, index) => (
                    <div key={index} className="TopHeader-Left">
                      <div className="d-block d-sm-flex align-items-center justify-content-center justify-content-sm-start py-1">
                        {isEmpty(e?.mobile) != '' ?
                          <a href={`tel:${isEmpty(e?.mobile)}`} className="d-block">
                            <div className="d-flex align-items-center me-4">
                              <i className="ic_telephone me-2" /><span className="d-block">{isEmpty(e?.country_code)} {isEmpty(e?.mobile)}</span>
                            </div>
                          </a>
                          : ''}
                        {isEmpty(e?.email) != '' ?
                          <a href={`mailto:${isEmpty(e?.email)}`} className="">
                            <span className="">
                              <i className="ic_envelope me-2" /><span className="">{isEmpty(e?.email)}</span>
                            </span>
                          </a>
                          : ''}
                      </div>
                    </div>))}
                </div>

                <div className="top-bar-col">
                  <div className="TopHeader-Right">
                    <ul className="user-name">
                      {/* <li>
                        <div className="user-icon py-1">
                          <span className="d-block">{Object.keys(this.props.loginData).length > 0 && <>{isEmpty(this.props.loginData.first_name) + " "} {isEmpty(this.props.loginData.last_name)}</>}</span>
                          <i className="ic_user ms-2"></i>
                          <ul className="user-dropdown">
                            {Object.keys(isEmpty(this.props.loginData)).length === 0 && (
                              <li>
                                <div className="d-flex align-items-center">
                                  <div onClick={() => { this.props.getSectionUrl("sign-in"); this.props.setIsRegisterModalOpen(false); this.loginClick(); }} className={`SignInUp ${this.props.storeSectionUrl === "sign-in" ? "Header-Active-Color" : ""}`}>
                                    Sign In
                                  </div>

                                  <div className="SignInUp mx-2">|</div>

                                  <div onClick={() => { this.props.getSectionUrl("sign-up"); this.regiterClick(); this.props.setIsLoginOpenModal(false) }} className={`SignInUp ${this.props.storeSectionUrl === "sign-up" ? "Header-Active-Color" : ""}`}>
                                    Sign Up
                                  </div>
                                </div>
                              </li>
                            )}

                            {Object.keys(isEmpty(this.props.loginData)).length > 0 && (
                              <li>
                                <Link to={"/profile"} className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "account" ? "Header-Active-Color" : ""}`} onClick={(event) => { if (window.location.pathname === "/profile") { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } sessionStorage.setItem("storeUrl", "/profile"); this.props.getSectionUrl("account"); }}>
                                  <i className="ic_myaccount" />
                                  <div>My Account</div>
                                </Link>
                              </li>
                            )}

                            {Object.keys(isEmpty(this.props.loginData)).length > 0 && (
                              <li>
                                <Link onClick={(event) => { if (window.location.pathname === `/profile/order/${'true'}`) { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } sessionStorage.setItem("storeUrl", `/profile/order/${'true'}`); this.props.getSectionUrl("order"); }} to={`/profile/order/${'true'}`} href="#" className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "order" ? "Header-Active-Color" : ""}`}>
                                  <i className="ic_shopping_bag" />
                                  <div>My Orders</div>
                                </Link>
                              </li>
                            )}

                            {Object.keys(isEmpty(this.props.loginData)).length > 0 && (
                              <li>
                                <Link onClick={(event) => { if (window.location.pathname === `/profile/coupon-code/${'true'}`) { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } sessionStorage.setItem("storeUrl", `/profile/coupon-code/${'true'}`); this.props.getSectionUrl("coupon-code"); }} to={`/profile/coupon-code/${'true'}`} className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "coupon-code" ? "Header-Active-Color" : ""}`}>
                                  <i className="ic_apply_coupon" />
                                  <div>Coupon Code</div>
                                </Link>
                              </li>
                            )}

                            <li>
                              <Link to={"/contactus"} className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "contact" ? "Header-Active-Color" : ""}`} onClick={(event) => { if (window.location.pathname === "/contactus") { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } sessionStorage.setItem("storeUrl", "/contactus"); this.props.getSectionUrl("contact") }}>
                                <i className="ic_user_term"></i>
                                <div>Contact Preferences</div>
                              </Link>
                            </li>

                            {Object.keys(isEmpty(this.props.loginData)).length > 0 && (
                              <li>
                                <div className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "logout" ? "Header-Active-Color" : ""}`} onClick={() => { sessionStorage.removeItem("storeUrl"); this.props.getSectionUrl("logout"); this.logout(); }}>
                                  <i className="ic_logout" />
                                  <div>Log Out</div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </li> */}

                      {Object.keys(this.props.storeEntityId).length > 0 ? this.state.getCurrencyDropDownData.length > 1 &&
                        <li>
                          <Form.Select value={this.props.storeCurrency} className="councyBlog top-header-text-color top-header-text-size top-header-text-hover" onChange={(e) => {
                            const data = this.state.getCurrencyDropDownData[e.target.selectedIndex]
                            if (data.is_store === 1) {
                              this.props.getStoreCurrency(data.mp_store_price)
                              this.updateCartCurrency(data.mp_store_price, "onChange")
                            } else {
                              if (isEmpty(data.mp_b2c_url) !== "") {
                                window.open(data?.mp_b2c_url, '_blank', '');
                              }
                            }
                          }} aria-label="Default select example">
                            {isEmpty(this.state.getCurrencyDropDownData) && this.state.getCurrencyDropDownData.map((e, i) => {
                              return <option style={{
                                backgroundColor: this.props.storeCurrency === isEmpty(e.mp_store_price) ? "var(--theme-btn-primary-hover-color)" : ""
                              }} key={i}
                                value={isEmpty(e.mp_store_price)}>
                                {isEmpty(e.mp_store_price)}</option>
                            })}
                          </Form.Select>
                        </li>
                        :
                        ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Navbar collapseOnSelect expand="lg">
            <Container>
              <Navbar.Brand href="/" onClick={(event) => { if (window.location.pathname === `/`) { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } sessionStorage.setItem("storeUrl", `/`); }} className="App-Logo">
                {this.state.storeHeaderLogo.length > 0 && (this.state.storeHeaderLogo.map((h, index) => {
                  return (<React.Fragment key={index}>
                    {isEmpty(h.image) !== "" &&
                      (<img src={isEmpty(h.image)} alt="" className="img-fluid" />)
                    }
                  </React.Fragment>)
                }))
                }
              </Navbar.Brand>
              <Navbar.Toggle id="navbartoggler" aria-controls="responsive-navbar-nav"><i className="ic_hamburger_menu" onClick={() => this.NavbarToggle()}></i></Navbar.Toggle>
              <Navbar.Collapse id="responsive-navbar-nav" className="main_header">
                <Nav className="mx-auto">
                  {isEmpty(this.state.storeHeaderData) && Object.keys(this.state.storeHeaderData).length > 0
                    && isEmpty(this.state.storeHeaderData?.navigation_data) && this.state.storeHeaderData?.navigation_data.length > 0 &&
                    this.state.storeHeaderData?.navigation_data.map((e, index) => {

                      if (e.router_link === window.location.pathname && e.product_vertical_name != 'DIY') {
                        this.props.setMenuName(e.menu_name);
                      }

                      return (<React.Fragment key={index}>
                        {isEmpty(e.submenu) === 1 && e.sub_menu.length > 1 && e.product_vertical_name != 'DIY' ?
                          <div onClick={() => {
                            const arr = [...this.state.storeHeaderData?.navigation_data]
                            if (document.getElementsByClassName("nav-link")[index] !== null && document.getElementsByClassName("nav-link")[index] !== undefined) {
                              if (!arr[index].isOpen) {
                                document.getElementsByClassName("Mega-menu")[index].classList.add("my-class");
                                document.getElementsByClassName("nav-link")[index].classList.add("active");
                              } else {
                                document.getElementsByClassName("Mega-menu")[index].classList.remove("my-class");
                                document.getElementsByClassName("nav-link")[index].classList.remove("active");
                              }
                            }
                            arr[index].isOpen = !arr[index].isOpen
                            this.setState({
                              storeHeaderData: this.state.storeHeaderData
                            })

                          }} className="nav-link dropdown-navbar megamenu_navbar">
                            <div className="d-flex align-items-center justify-content-between">
                              <span className={`MainNameHeader ${this.props.storeSectionUrl === isEmpty(e.product_vertical_name) ? "Header-Active-Color" : ""}`}
                                onClick={(event) => this.urlClick(e, event, e, e.menu_name)}>{e.menu_name}</span>
                              <div className="responsiveArrow">
                                <i className="minus-plus ic_plus"></i>
                                <i className="minus-plus ic_minus"></i>
                              </div>
                            </div>
                            <div className="Mega-menu">
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-9">
                                    <div className="row">
                                      {isEmpty(e.sub_menu) !== "" && e?.sub_menu.length > 0 && e?.sub_menu.map((s, index2) => {
                                        return (
                                          isEmpty(s.detailArr) !== "" && s.detailArr.length > 0 &&
                                          <div key={index2} className={`col-lg-3`}>
                                            <div className="SubNameHeader py-2">{isEmpty(s.display_name)}</div>
                                            {s.detailArr.length > 10 ?
                                              <div className="gridView">
                                                {splicaArrayIntoColumns(s.detailArr, 2, true).map((e1, index3) => {
                                                  return (

                                                    e1.map((s1, i) => {
                                                      return (
                                                        <Link onClick={(event) => {
                                                          this.urlClick(s1, event, e, e.menu_name); this.NavbarOff();
                                                        }} className={`subNavName ${this.props.storeSubNavbarName === (isEmpty(s1.title) !== "" ? s1.title.toUpperCase() + isEmpty(s1.vertical_code) : "") && "Header-Active-Color"}`}
                                                          to={s1.url} key={i}>
                                                          {isEmpty(s1.icon) !== "" && <i className={`me-2 ${isEmpty(s1.icon)}`}></i>}
                                                          {isEmpty(s1.title) !== "" ? s1.title?.toUpperCase() : ""}
                                                        </Link>
                                                      )
                                                    })
                                                  )
                                                })}
                                              </div>
                                              :
                                              isEmpty(s.detailArr) !== "" && s.detailArr.map((s1, i) => {
                                                return (
                                                  <Link key={i} onClick={(event) => { this.urlClick(s1, event, e, e.menu_name); this.NavbarOff(); }}
                                                    className={`subNavName ${s1.title.toUpperCase() + isEmpty(s1.vertical_code)} ${this.props.storeSubNavbarName === (isEmpty(s1.title) !== "" ? s1.title.toUpperCase() + (s1.vertical_code) : "") && "Header-Active-Color"} `}
                                                    to={s1.url} >
                                                    {isEmpty(s1.icon) !== "" && <i className={`me-2 ${isEmpty(s1.icon)}`}></i>}
                                                    {isEmpty(s1.title) !== "" ? s1.title?.toUpperCase() : ""}
                                                  </Link>
                                                )
                                              })
                                            }
                                          </div>
                                        )
                                      })}
                                    </div>

                                  </div>
                                  <div className="col-lg-3 pe-0">
                                    <div className="HeaderImages position-relative">
                                      {isEmpty(e.image) !== "" && e.image !== "" && <img alt="" src={isEmpty(e.image)} className="w-100 h-100" />}
                                      <div className="headerbtn">
                                        {isEmpty(e.banner_url) !== "" && e.banner_url !== "" && <a className="" onClick={(event) => {
                                          if (window.location.pathname === e.banner_url) {
                                            event.stopPropagation()
                                            event.preventDefault()
                                            this.props.navigate("/-")
                                          }
                                          sessionStorage.setItem("storeUrl", e.banner_url)
                                          jewelSessionData()
                                          diamoSessionData()
                                          looseSessionData()
                                          sessionStorage.removeItem("collection_id");
                                          sessionStorage.removeItem("finish_type");
                                          if (sessionStorage.getItem("collection") !== null) {
                                            sessionStorage.removeItem("collection")
                                          }
                                          window.location.href = e.banner_url
                                        }} href={e.banner_url}> Shop Now</a>
                                        }
                                      </div>
                                      <div className="linear"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <>
                            {e.sub_menu.length == 1 || e.product_vertical_name == 'DIY' ?
                              <div className="dropdown-navbar dropdown">
                                {isEmpty(e.menu_name) !== "" ? <span
                                  // to={isEmpty(e.product_vertical_name) === "FRAME" ? "/frame" : isEmpty(e.router_link) !== "" ? (e.router_link) : "/"}
                                  className={`MainNameHeader dropbtn   ${this.props.storeSectionUrl === (isEmpty(e.product_vertical_name) === "OTHER" ?
                                    isEmpty(e.menu_name) : isEmpty(e.product_vertical_name)) ? "Header-Active-Color" : ""} `}>
                                  {isEmpty(e.menu_name) !== "" ? e.menu_name?.toUpperCase() : e.menu_name}
                                </span>
                                  :
                                  <React.Fragment key={index}></React.Fragment>
                                }
                                <div className="dropdown-content">
                                  {/* {e.sub_menu.length != 0 && e.sub_menu[0].detailArr.map((d, di) =>
                                    <Link onClick={(event) => {
                                      this.urlClick(d, event, e)
                                    }}
                                      className={`subNavName ${this.props.storeSubNavbarName === (isEmpty(d.title) !== "" ? d.title.toUpperCase() + isEmpty(d.vertical_code) : "") && "Header-Active-Color"}`}
                                      to={d.url} key={di}>
                                      {isEmpty(d.icon) !== "" && <i className={`me-2 ${isEmpty(d.icon)}`}></i>}
                                      {isEmpty(d.title) !== "" ? d.title : ""}
                                    </Link>
                                  )} */}
                                  {/* <Link
                                    className={`subNavName ${false && "Header-Active-Color"}`}
                                    to={'/start-with-diamond-b2c'} >
                                    Start With Diamond
                                  </Link> */}
                                  {e.sub_menu.length != 0 && e.sub_menu.map((d, di) => {
                                    if (window.location.pathname.split("/").includes("start-with-a-setting")) {
                                      this.props.setMenuName("START WITH A SETTING");
                                    } else if (window.location.pathname.split("/").includes("start-with-diamond")) {
                                      this.props.setMenuName("START WITH A DIAMOND");
                                    }
                                    return d.sub_category === "STRWS" ?
                                      <Link onClick={(event) => {
                                        this.urlClick({ url: '/jewellery/start-with-a-setting', product_vertical_name: d.product_vertical, title: d.display_name }, event, e, d.display_name)
                                        this.props.getSectionUrl(isEmpty(d.product_vertical))
                                      }}
                                        className={`subNavName ${this.props.storeSubNavbarName === (isEmpty(d.display_name) !== "" ? d.display_name.toUpperCase() + isEmpty(d.product_vertical) : "") && "Header-Active-Color"}`}
                                        to={'/jewellery/start-with-a-setting'} key={di}>
                                        {/* {isEmpty(d.icon) !== "" && <i className={`me-2 ${isEmpty(d.icon)}`}></i>} */}
                                        {isEmpty(d.display_name) !== "" ? d.display_name : ""}
                                      </Link>
                                      :
                                      <Link onClick={(event) => {
                                        this.urlClick({ url: '/start-with-diamond', product_vertical_name: d.product_vertical, title: d.display_name }, event, e, d.display_name)
                                      }}
                                        className={`subNavName ${this.props.storeSubNavbarName === (isEmpty(d.display_name) !== "" ? d.display_name.toUpperCase() + isEmpty(d.product_vertical) : "") && "Header-Active-Color"}`}
                                        to={'/start-with-diamond'} key={di}>
                                        {/* {isEmpty(d.icon) !== "" && <i className={`me-2 ${isEmpty(d.icon)}`}></i>} */}
                                        {isEmpty(d.display_name) !== "" ? d.display_name : ""}
                                      </Link>
                                  }
                                  )}
                                </div>
                              </div>
                              :
                              <Nav.Link href={isEmpty(e.router_link)} className="dropdown-navbar">
                                {isEmpty(e.menu_name) !== "" ? <span onClick={(event) => {
                                  this.otherUrl(e, event)
                                }}
                                  className={`MainNameHeader   ${this.props.storeSectionUrl === (isEmpty(e.product_vertical_name) === "OTHER" ?
                                    isEmpty(e.menu_name) : isEmpty(e.product_vertical_name)) ? "Header-Active-Color" : ""} `}>
                                  {isEmpty(e.menu_name) !== "" ? e.menu_name?.toUpperCase() : e.menu_name}
                                </span>
                                  :
                                  <React.Fragment key={index}></React.Fragment>
                                }
                              </Nav.Link>
                            }
                            {/* <Link to={isEmpty(e.router_link)} className="dropdown-navbar">
                            {isEmpty(e.menu_name) !== "" ? <span onClick={(event) => {
                              this.otherUrl(e, event)
                            }}
                              // to={isEmpty(e.product_vertical_name) === "FRAME" ? "/frame" : isEmpty(e.router_link) !== "" ? (e.router_link) : "/"}

                              className={`MainNameHeader   ${this.props.storeSectionUrl === (isEmpty(e.product_vertical_name) === "OTHER" ?
                                isEmpty(e.menu_name) : isEmpty(e.product_vertical_name)) ? "Header-Active-Color" : ""} `}>
                              {isEmpty(e.menu_name) !== "" ? e.menu_name?.toUpperCase() : e.menu_name}
                            </span>
                              :
                              <React.Fragment key={index}></React.Fragment>
                            }
                          </Link> */}
                          </>

                        }
                      </React.Fragment>)
                    })}
                </Nav>
                <Nav className="menu-icons">
                  <div className="nav-link">
                    <div className="user-icon">
                      <span className="user-text">{Object.keys(this.props.loginData).length > 0 && <>{isEmpty(this.props.loginData.first_name) + " "}</>}</span>
                      <i className="ic_user secondHeaderRightSide"></i>
                      <ul className="user-dropdown">
                        {this.props.storeEntityId.transaction_type === "CHECKOUT" && <>
                          {Object.keys(isEmpty(this.props.loginData)).length === 0 && (
                            <li>
                              <div className="d-flex align-items-center">
                                <div onClick={() => { this.props.getSectionUrl("sign-in"); this.props.setIsRegisterModalOpen(false); this.loginClick(); this.NavbarOff(); }} className={`SignInUp ${this.props.storeSectionUrl === "sign-in" ? "Header-Active-Color" : ""}`}>
                                  Sign In
                                </div>

                                <div className="SignInUp mx-2">|</div>

                                <div onClick={() => { this.props.getSectionUrl("sign-up"); this.regiterClick(); this.props.setIsLoginOpenModal(false); this.NavbarOff(); }} className={`SignInUp ${this.props.storeSectionUrl === "sign-up" ? "Header-Active-Color" : ""}`}>
                                  Sign Up
                                </div>
                              </div>
                            </li>
                          )}

                          {Object.keys(isEmpty(this.props.loginData)).length > 0 && (
                            <li>
                              <Link to={"/profile"} className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "account" ? "Header-Active-Color" : ""}`} onClick={(event) => { if (window.location.pathname === "/profile") { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } this.NavbarOff(); sessionStorage.setItem("storeUrl", "/profile"); this.props.getSectionUrl("account"); }}>
                                <i className="ic_my_account" />
                                My Account
                              </Link>
                            </li>
                          )}

                          {Object.keys(isEmpty(this.props.loginData)).length > 0 && (
                            <li>
                              <Link onClick={(event) => { if (window.location.pathname === `/profile/order/${'true'}`) { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } sessionStorage.setItem("storeUrl", `/profile/order/${'true'}`); this.props.getSectionUrl("order"); this.NavbarOff(); }} to={`/profile/order/${'true'}`} href="#" className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "order" ? "Header-Active-Color" : ""}`}>
                                <i className="ic_shopping_bag" />
                                My Orders
                              </Link>
                            </li>
                          )}

                          {Object.keys(isEmpty(this.props.loginData)).length > 0 && (
                            <li>
                              <Link onClick={(event) => { if (window.location.pathname === `/profile/coupon-code/${'true'}`) { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } sessionStorage.setItem("storeUrl", `/profile/coupon-code/${'true'}`); this.props.getSectionUrl("coupon-code"); this.NavbarOff(); }} to={`/profile/coupon-code/${'true'}`} className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "coupon-code" ? "Header-Active-Color" : ""}`}>
                                <i className="ic_apply_coupon" />
                                Coupon Code
                              </Link>
                            </li>
                          )}
                        </>}

                        <li>
                          <Link to={"/contactus"} className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "contact" ? "Header-Active-Color" : ""}`} onClick={(event) => { if (window.location.pathname === "/contactus") { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } this.NavbarOff(); sessionStorage.setItem("storeUrl", "/contactus"); this.props.getSectionUrl("contact") }}>
                            <i className="ic_user_term"></i>
                            Contact Preferences
                          </Link>
                        </li>

                        {this.props.storeEntityId.transaction_type === "CHECKOUT" && Object.keys(isEmpty(this.props.loginData)).length > 0 && (
                          <li>
                            <div className={`d-flex align-items-center SignInUp ${this.props.storeSectionUrl === "logout" ? "Header-Active-Color" : ""}`} onClick={() => { sessionStorage.removeItem("storeUrl"); this.props.getSectionUrl("logout"); this.logout(); this.NavbarOff(); }}>
                              <i className="ic_logout" />
                              Log Out
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <Link to="/favourite-items" className="nav-link" onClick={(event) => { if (window.location.pathname === `/favourite-items`) { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } this.NavbarOff(); sessionStorage.setItem("storeUrl", `/favourite-items`); this.props.getSectionUrl("fav-icon"); }}>
                    <i className={`${this.props.storeSectionUrl === "fav-icon" ? "Header-Active-Color" : ""} ic_heart secondHeaderRightSide`}>
                      <div className="count-icon">{this.props.storeFav}</div>
                    </i>
                  </Link>

                  {isEmpty(this.props.storeEntityId.transaction_type) !== "INQUIRY" && this.props.storeEntityId.transaction_type === "CHECKOUT" && <Link className="nav-link" to="/shopping-cart" onClick={(event) => { if (window.location.pathname === `/shopping-cart`) { event.stopPropagation(); event.preventDefault(); this.props.navigate("/-"); } this.NavbarOff(); sessionStorage.setItem("storeUrl", `/shopping-cart`); this.props.getSectionUrl("shopping-cart"); this.props.getisProcessToCheckoutClick(true); }}>
                    <i className={`${this.props.storeSectionUrl === "shopping-cart" ? "Header-Active-Color" : ""} ic_shopping_bag secondHeaderRightSide`}>
                      <div className="count-icon">{this.props.counter}</div>
                    </i>
                  </Link>}

                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          {this.props.openGetCartNavbar &&
            <Container>
              <Offcanvas style={{ zIndex: "100000" }} placement='end' show={this.props.getOpenGetCartNavbar} onHide={this.props.getOpenGetCartNavbar}>
                <Offcanvas.Header closeButton className="border-bottom">
                  <div className="row">
                    <div className="col-12  fs-4 main-title01">Cart Items</div>
                  </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  {this.props.storeGetCartData.length > 0 && this.props.storeGetCartData.map((e, i) => {
                    let obj = {}
                    let jewelImage = ""
                    let Diamond = ""
                    if (e.data.length >= 2) {
                      const getImageJewel = e.data.filter((s) => jewelVertical(s.vertical_code) === true)
                      if (getImageJewel.length > 0) {
                        obj = getImageJewel[0]
                        jewelImage = getImageJewel[0].item_image === "" ? getImageJewel[0].image : getImageJewel[0].item_image
                      }

                      const diamoImage = e.data.filter((s) => s.stone_position === "CENTER")

                      if (diamoImage.length > 0) {
                        Diamond = diamoImage[0].item_image === "" ? diamoImage[0].image : diamoImage[0].item_image
                      } else {
                        const getData = e.data.filter((s) => s.stone_position !== "CENTER" && jewelVertical(s.vertical_code) === true)
                        if (getData.length > 0) {
                          Diamond = getData[0].item_image === "" ? getData[0].image : getData[0].item_image
                        }
                      }

                      if (jewelImage === "") {
                        jewelImage = "https://via.placeholder.com/500X500"
                      }
                      if (Diamond === "") {
                        Diamond = "https://via.placeholder.com/500X500"
                      }
                    }
                    return (
                      <React.Fragment key={i}>
                        {e.data.length === 1 ?
                          <div className="offcanvas-items">
                            <div className="CartItems">
                              <div className="CartItems_left">
                                {e.data[0].vertical_code === "DIAMO" ?
                                  e.data[0].item_image !== "" ?
                                    <div className="position-relative d-flex justify-content-center m-auto">
                                      <img src={e.data[0].item_image ? e.data[0].item_image : e.data[0].image} alt="" className={`img-fluid position-relative`} />
                                      {/* {e.data[0].vertical_code === "DIAMO" &&
                                                  <>
                                                    <span className="diamond-width fw-400 small-title">{e.data[0].st_length}</span>
                                                    <span className="diamond-height fw-400 small-title">{e.data[0].st_depth}</span>
                                                  </> 
                                                } */}
                                    </div>
                                    :
                                    <img className="img-fluid " src={e.data[0].item_image === "" ? e.data[0].image : e.data[0].item_image} alt=""></img>
                                  :
                                  <img className="img-fluid " src={e.data[0].item_image === "" ? e.data[0].image : e.data[0].item_image} alt=""></img>
                                }
                              </div>

                              <div className="CartItems_right h-100">
                                <div className="CartItems_title">{e.data[0].product_name}</div>
                                <div className="CartItems_price">{e.data[0].currency + " "}
                                  {numberWithCommas((parseFloat(e?.data[0]?.item_price)).toFixed(2))}</div>
                                <div className="CartItems_qty"><span>Quantity : {e.data[0].item_qty}</span></div>
                                <div className="CartItems_price">
                                  <div style={{ marginLeft: "auto", fontSize: "14px" }} className="mt-1 fw-bold">{e.data[0].currency}{" "}{(e.item_price_display)}</div>
                                </div>
                              </div>
                              <div className="prodect_remove">
                                {<i className="cursor-pointer ic_dustbin ms-3" onClick={() => this.removeItems(e.data, i)}></i>}
                              </div>
                            </div>
                          </div>
                          :
                          <div className="offcanvas-items">
                            <div className="CartItems">
                              <div className="CartItems_left">
                                <div className="position-relative d-flex justify-content-center m-auto">
                                  <img style={{ cursor: "pointer" }} alt="" src={jewelImage} className={`w-100 h-100 position-relative`} />
                                  <div className="img-product text-end">
                                    <img
                                      src={Diamond}
                                      // src={e.data[1].item_image === "" ? e.data[1].image : e.data[1].item_image}
                                      alt="" className={`img-fluid border`} />
                                  </div>
                                </div>
                              </div>
                              <div className="CartItems_right h-100">
                                <div className="CartItems_title">{obj?.product_name}</div>
                                <div className="CartItems_price">{obj?.currency + " "}
                                  {numberWithCommas((parseFloat(obj?.item_price)).toFixed(2))}</div>

                                {/* <div>Certificate : {e.data[1].cert_no}</div> */}
                                <div className="CartItems_qty"><span>Quantity : {e.data[0].item_qty}</span></div>
                                <div className="CartItems_price">

                                  <div style={{ marginLeft: "auto", fontSize: "14px" }} className="mt-1">{obj?.currency}{" "}{(e.item_price_display)}</div>
                                </div>
                              </div>
                              <div className="prodect_remove">
                                {<i className="cursor-pointer ic_dustbin ms-3" onClick={() => this.removeItems(e.data, i)}></i>}
                              </div>
                            </div>
                          </div>}
                      </React.Fragment>
                    )
                  })}
                </Offcanvas.Body>
                <div className="d-flex align-items-center justify-content-between px-3 pt-2 border-top">
                  <span style={{ fontSize: "21px" }} className="main-title01"> Cart Subtotal :</span>
                  <div className="mx-1 mb-0 main-title01 fw-bold">{" "}{this.props.storeCurrency} {numberWithCommas(((this.props.storeGetCartPrice)).toFixed(2))}</div>
                </div>
                <div className="p-3">
                  <div className="row mx-0">
                    <div className="col-6 ps-0">
                      <button onClick={() => {
                        this.props.getSectionUrl("shopping-cart")
                        this.props.navigate("/shopping-cart");
                        this.props.getOpenGetCartNavbar(false)
                      }} className="btn button-thamebalck w-100">Go to Cart</button>
                    </div>
                    <div className="col-6 pe-0">
                      <button onClick={() => {
                        if (Object.keys(this.props.loginData).length > 0) {
                          this.props.getisProcessToCheckoutClick(false)
                          this.props.setIsGuestIdLogin(true)
                        } else {
                          this.props.setIsLoginOpenModal(true)
                          this.props.getisProcessToCheckoutClick(false)
                          this.props.setIsGuestIdLogin(false)
                          this.props.setIsChangePasswordLoginClick(false)
                          this.props.setisCartLoginClick(true)
                        }
                        this.props.navigate("/shopping-cart");
                        this.props.getOpenGetCartNavbar(false)
                      }} className="btn button-thamebalck w-100">Check Out</button>
                    </div>
                  </div>

                </div>
              </Offcanvas>
            </Container>}
        </header>

        {this.props.isLoginModalOpen === true && (<Login logologin={this.state.storeHeaderLogo} isNavigateToHome={false} isCloseButtonHide={false} />)}

        {this.props.isRegisterModalOpen === true ? (<Register logoRegister={this.state.storeHeaderLogo} isNavigateToHome={false} isCloseButtonHide={false} />) : ("")}

        <ToastComponent
          show={this.state.toastShow}
          success={this.state.isSuccess}
          onClose={() => this.setState({ toastShow: false, })}
          header={this.state.isSuccess ? "Success" : "Error"}
          toastBody={<div>{this.state.toastMsg}</div>}
        />

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const counter = state.count;
  const loginData = state.data;
  const isforgot = state.isforgot;
  const storeFav = state.storeFavIconCount;
  const storeEntityId = state.storeEntityId;
  const isProcessToCheckoutClick = state.isProcessToCheckoutClick
  const storeSectionUrl = state.storeSectionUrl
  const isLoginModalOpen = state.isLoginModalOpen
  const isRegisterModalOpen = state.isRegisterModalOpen
  const isGuestIdLogin = state.isGuestIdLogin
  const openGetCartNavbar = state.openGetCartNavbar
  const storeGetCartData = state.storeGetCartData
  const storeGetCartPrice = state.storeGetCartPrice
  const storeSubNavbarName = state.storeSubNavbarName
  const storeCurrency = state.storeCurrency
  const isLogin = Object.keys(state.data).length > 0;

  return {
    isLogin,
    storeSubNavbarName,
    counter,
    loginData,
    isforgot,
    storeCurrency,
    storeFav,
    storeEntityId,
    isProcessToCheckoutClick,
    storeSectionUrl,
    isLoginModalOpen,
    isRegisterModalOpen,
    isGuestIdLogin,
    openGetCartNavbar,
    storeGetCartData,
    storeGetCartPrice,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {

    getStoreCurrency: (index) => dispatch({ type: "storeCurrency", storeCurrency: index }),
    getStoreSubNavbarName: (index) => dispatch({ type: "storeSubNavbarName", storeSubNavbarName: index }),
    setStoreFilterValueName: (index) => dispatch({ type: "storeFilterValueName", storeFilterValueName: index }),
    setIsChangePasswordLoginClick: (index) => dispatch({ type: "isChangePasswordLoginClick", isChangePasswordLoginClick: index }),
    setisCartLoginClick: (index) => dispatch({ type: "isCartLoginClick", isCartLoginClick: index }),
    setIsGuestIdLogin: (index) => dispatch({ type: "isGuestIdLogin", isGuestIdLogin: index }),
    setIsDiamoDIY: (index) => dispatch({ type: "isDiamoDIY", isDiamoDIY: index }),
    setIsJewelDIY: (index) => dispatch({ type: "isJewelDIY", isJewelDIY: index }),
    getStoreGetLogo: (index) => dispatch({ type: "storeGetLogo", storeGetLogo: index }),
    getStoreGetCartPrice: (index) => dispatch({ type: "storeGetCartPrice", storeGetCartPrice: index }),
    getStoreGetCartData: (index) => dispatch({ type: "storeGetCartData", storeGetCartData: index }),
    getOpenGetCartNavbar: (index) => dispatch({ type: "openGetCartNavbar", openGetCartNavbar: index }),
    getCountNo: (index) => dispatch({ type: "countOfCartItems", count: index }),
    getLoginData: (index) => dispatch({ type: "getLoginDetails", data: index }),
    getIsForgot: (index) => dispatch({ type: "storeForgotVar", value: index }),
    getisProcessToCheckoutClick: (index) => dispatch({ type: "isProcessToCheckoutClick", isProcessToCheckoutClick: index }),
    getCountOfCartItems: (index) => dispatch({ type: "countOfCartItems", count: index }),
    storeFavCount: (index) => dispatch({ type: "storeFavCount", storeFavCount: index }),
    getStoreFavIcon: (index) =>
      dispatch({ type: "storeFavCount", storeFavCount: index }),
    getSectionUrl: (index) =>
      dispatch({
        type: "storeSectionUrl",
        storeSectionUrl: index,
      }),
    setIsLoginOpenModal: (index) =>
      dispatch({
        type: "isLoginModalOpen",
        isLoginModalOpen: index,
      }),
    setIsRegisterModalOpen: (index) =>
      dispatch({
        type: "isRegisterModalOpen",
        isRegisterModalOpen: index,
      }),
    setisGuestIdLogin: (index) =>
      dispatch({
        type: "isGuestIdLogin",
        isGuestIdLogin: index,
      }),
    initialDiyState: () => {
      dispatch(diamondParamsAction({}))
      dispatch(diamondSelectAction({}));
      dispatch(selectRingAction({}));
      dispatch(ringAndDiamondAction({}))
    },
    setMenuName: (menu_name) => {
      dispatch(handleMenuNameAction(menu_name))
    },
  };

};

export default connect(mapStateToProps, mapDispatchToProps, null)(Header);

